<template>
    <div id="main">
        <div id="manu">
          <navigation />
        </div>
        <div id="contentBox">
          <!-- 背景 -->
          <div class="registervideoContainer"></div>
          <div class="w">
              <ul>
                <li v-for="(item, index) in filteredResults" :key="index">
                  {{ item }}
                </li>
              </ul>
            <ul class="Netch">
              <li>
                <div class="netch">
                  <a href="https://410121.xyz/s/Netch" target="_blank">Netch下载</a>
                  <br>
                  <router-link to="/ront">1G试用链接</router-link>
                </div>
              </li>
            </ul>
            <ul id="Netch">
              <li>
                <div class="netch">
                  <h1>解压之后，配置</h1>
                  <img src="@/assets/netch/1.jpg" alt="">
                  <img src="@/assets/netch/2.jpg" alt="">
                  <img src="@/assets/netch/3.jpg" alt="">
                  <img src="@/assets/netch/4.jpg" alt="">
                  <img src="@/assets/netch/5.jpg" alt="">
                  <img src="@/assets/netch/6.jpg" alt="">
                  <img src="@/assets/netch/7.jpg" alt="">
                  <img src="@/assets/netch/8.jpg" alt="">
                  <img src="@/assets/netch/9.jpg" alt="">
                  <h1>！！！题外篇！！！</h1>
                  <img src="@/assets/netch/10.jpg" alt="">
                  <img src="@/assets/netch/11.jpg" alt="">
                  <img src="@/assets/netch/12.jpg" alt="">
                  <img src="@/assets/netch/13.jpg" alt="">
                  <img src="@/assets/netch/14.jpg" alt="">

                </div>
              </li>
            </ul>
          <!-- 悬浮索引 -->
          <!-- <div id="sidebar">
            <li>文章目录</li>
            <a href="#android">1. 安卓</a>
            <a href="#ios">2. 苹果</a>
            <a href="#pc">3. PC端</a>
          </div> -->
          </div>
          <Pfooter/>
        </div>
    </div>
</template>

<script>
export default {
  props: {
        keywords: {
            type: String,
            required: true
        }
    },
  data() {
    return {
      encodedContent: "vmess://ewogICJ2IjogIjIiLAogICJwcyI6ICJqcCIsCiAgImFkZCI6ICIxODUuMzkuMzEuMzQiLAogICJwb3J0IjogNDgxNTgsCiAgImlkIjogIjY4Mzk1MmFhLWMxNGItNGZhMC1lYWZkLTM5ZTdkYjE2OTQyZiIsCiAgImFpZCI6IDAsCiAgIm5ldCI6ICJ3cyIsCiAgInR5cGUiOiAibm9uZSIsCiAgImhvc3QiOiAiIiwKICAicGF0aCI6ICIvIiwKICAidGxzIjogIm5vbmUiCn0=",
      goodsList: ["html", "css", "javascript", "html5", "css3", "vue", "react", "node"],
      filteredResults: []
    };
  },
  created() {
        this.filterResults();
  },
  methods: {
    copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.encodedContent;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert("已复制到剪贴板！");
    },
    filterResults() {
      this.filteredResults = this.goodsList.filter(item => item.includes(this.keywords));
    }, 
  },
  watch: {
        keywords() {
            this.filterResults();
        }
    }
};
</script>

<style scoped>
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  #home {
    width: 100%;
  }
  #contentBox {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    /* z-index: -1; */
  }
  .registervideoContainer {
    background-size: cover;
    background-repeat: no-repeat;
    /* 保证盒子始终在窗口中心 */
    background-position: center;
    /* 避免窗口周围留白 */
    width: 100%;
    height: 100%;
    position: fixed;
    margin-left: 0rem;
    z-index: -1;
    background-image: url(../assets/2.jpg);
    /* 增加虚化效果，参数可以根据需要调整 */
    /* filter: blur(6px); */
  }
  .Netch {
    color: aliceblue;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 90%;
    margin-top: 50px;
    margin-left: 9%;
  }
  .Netch > li {
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
  #Netch {
    color: aliceblue;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 90%;
    margin-top: 50px;
    margin-left: 9%;
    margin-bottom: 10%;
  }
  #Netch > li {
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .registervideoContainer {
    margin-left: -9.5%;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -2%;
  }
  .Netch {
    color: aliceblue;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 80%;
    margin-top: 50px;
    margin-left: 16%;
  }
  #Netch {
    color: aliceblue;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 80%;
    margin-top: 50px;
    margin-left: 16%;
    margin-bottom: 10%;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: 0%;
  }
  .Netch, #Netch {
    width: 74%;
    margin-left: 24%;
  }
  #Netch {
    margin-bottom: 14%;
  }
  #frp img {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: -0.5%;
  }
  .Netch, #Netch {
    margin-left: 10%;
  }
  #Netch {
    margin-bottom: 34%;
  }
  #frp img {
    width: 100%;
  }
}
</style>
