<template>
  <div id="miaoqu">
    <div id="manu">
      <navigation />
    </div>
    <!-- 渲染根节点 -->
    <iframe
      src="https://www.miaoqumh.org/"
      style="width: 100%; height: 915px; border: none;"
    ></iframe>
  </div>
</template>

<script>
</script>

<style scoped>
#manu{
  z-index: 9999999;
}
</style>
