<template>
  <div id="main">
    <div id="manu">
      <navigation />
    </div>
    <div id="contentBox">
      <!-- 背景 -->
      <div class="registervideoContainer">
      </div>
      <div class="w">
        <h1 id="t">部分代码预览</h1>
        <ul class="home_jineng">
        <li>
          <div class="pic">
            #model1 #个人信息 No="220054470204" Name="名字" Sex="性别" Class="大数据"
            infoStuList=[No,Name,Class,Sex] infoStuDict={ "学号":No, "姓名":Name,
            "班级":Class, "性别":Sex, } #网站信息 Url=r"http://www.nanshan.edu.cn/"
            UrlName="烟台南山学院" newsUrl=r"http://www.nanshan.edu.cn/nyyw.htm"
            newsName="南院要闻"
            htmlFileName=r"F:/python/220054470204-名字-任务1/附件2-南院要闻-烟台南山学院.html"
            infoSiteList=[Url,UrlName,newsUrl,newsName] infoSiteDict={ "主页":Url,
            "学校名称":UrlName, "板块网址":newsUrl, "板块名称":newsName, }
            htmlName=r"./附件3-页面结构.html" csvFileName=r"./附件4-newsTitles.csv"
            #目标网站header headers={
            "Accept":"text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8",
            "Accept-Encoding":"gzip, deflate",
            "Accept-Language":"zh-CN,zh;q=0.8,zh-TW;q=0.7,zh-HK;q=0.5,en-US;q=0.3,en;q=0.2",
            "Cookie": "JSESSIONID=1AE64ED0D7ACE627A38ABF249CF6F0B8", "User-Agent":
            "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:121.0) Gecko/20100101
            Firefox/121.0" } repeatTimes=20 def showInfo(info):
            print("学号:{},姓名:{},班级:{},性别:{}".format(info[0],info[1],info[2],info[3]))
            print(f"网站主页网址:{Url}\n网站单位名:'{UrlName}'")
            print(f"目标网址:{newsUrl}\n目标板块名称:'{newsName}'")
            print(f"离线目标页面文件:'{htmlFileName}'") def showInfoStuList(infoStuList):
            for info in infoStuList: print(info) def showInfoStuDict(infoStuDict): for k,v
            in infoStuDict.items(): print(f"{k}={v}") for i,(k,v) in
            enumerate(infoStuDict.items()): print(f"{i}:{k} = {v}") def
            showInfoSiteList(infoSiteList): for item in infoSiteList: print(item) def
            showInfoSiteDict(infoSiteDict): for k, v in infoSiteDict.items():
            print(f"{k}={v}") for i, (k, v) in enumerate(infoSiteDict.items()):
            print(f"{i}:{k} = {v}") if __name__ == "__main__": info=(No,Name,Class,Sex)
            print("1.调用函数:showInfo()") showInfo(info)
            print("2.调用函数:showInfoStuList()") showInfoStuList(infoStuList)
            print("3.调用函数:showInfoStuDict()") showInfoStuDict(infoStuDict)
            print("4.调用函数:showInfoSiteList()") showInfoSiteList(infoSiteList)
            print("5.调用函数:showInfoSiteDict()") showInfoSiteDict(infoSiteDict)
          </div>
          <div class="price">￥价格私聊</div>
          <div class="describe1">商务联系</div>
          <div class="describe2">各式程序定做</div>
        </li>
      </ul>
      <ul class="python">
        <li>
          <div class="pic">
            import requests #程序请求，得到网站的响应 from module1 import newsUrl,headers
            def isResponse(newsUrl,headers):
            respanse=requests.get(newsUrl,headers=headers) code=respanse.status_code
            return code if __name__=="__main__": print(isResponse(newsUrl,headers))
            <br>
            #model2 from module1 import htmlName from module1 import
            showInfoStuDict,infoStuDict,csvFileName from module1 import
            Url,newsUrl,htmlFileName import requests from bs4 import BeautifulSoup from
            module1 import newsUrl,headers,Url from module3 import isResponse import csv
            csvFileName = r"./附件4-newsTitles.csv" maxPage = '116' #拼接 def
            generateNewsUrl(newsUrl,maxPage): allPageUrl = [] */.*/i = newsUrl.rindex('.')
            leftRul=newsUrl[:i] print(f"leftRul1: {leftRul}") for j in range(int(maxPage) -
            1, 0, -1): allPageUrl.append(leftRul + '/' + str(j) + r".htm")
            print(allPageUrl) return allPageUrl
          </div>
          <div class="price">￥价格私聊</div>
          <div class="describe1">商务联系</div>
          <div class="describe2">各式程序定做</div>
        </li>
      </ul>
      <ul class="jar">
        <li>
          <div class="pic">
            <br>
            import javax.swing.*;
            <br>
            import java.awt.*;
            <br>
            import java.awt.event.ActionEvent;
            <br>
            import java.awt.event.ActionListener;
            <br>
            import java.util.ArrayList;
            <br>
            import java.util.List;
            <br>
            public class LibraryGUI extends JFrame {
                <br>
                //
                <br>
                内容
                <br>
            }
            <!-- <img src="@/assets/" alt=""> -->
          </div>
          <div class="price">￥价格私聊</div>
          <div class="describe1">商务联系</div>
          <div class="describe2">格式程序定做</div>
        </li>
      </ul>
      </div>
      <Pfooter />
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
.pic {
  overflow: auto;
  color: #333;
  font-size: 12px;
}
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
img {
  width: 100%;
  height: 100%;
  display: block;
}
#home {
  width: 100%;
}
#contentBox {
  width: 100%;
  height: 100%;
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
    /* //保证盒子始终在窗口中心 */
  background-position: center;
    /* //避免窗口周围留白 */
  width: 100%;
  height: 100%;
  margin-left: 0rem;
  position: fixed;
  z-index: -1;
  background-image: url(../assets/2.jpg);
  /* 增加虚化效果，参数可以根据需要调整 */
  filter: blur(6px);  
}
/* 内容 */
.home_jineng {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-left: -4%;
  margin-top: 30px;
  margin-bottom: 5%;
}
.home_jineng > li {
  width: 100%;
  /* 内容背景 */
  background: #911818;
  box-shadow: 0 0 10px #666;
  text-align: center;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
  padding: 15px;
  margin-right: -24%;
  box-sizing: border-box;
}

.home_jineng > li .pic {
  width: 100%;
  height: 235px;
  background: #fff;
}
.python {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-left: -4%;
  margin-top: 30px;
  margin-bottom: 5%;
}
.python > li {
  width: 100%;
  /* 内容背景 */
  background: #911818;
  box-shadow: 0 0 10px #666;
  text-align: center;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
  padding: 15px;
  margin-right: -24%;
  box-sizing: border-box;
}

.python > li .pic {
  width: 100%;
  height: 235px;
  background: #fff;
}
.jar {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-left: -4%;
  margin-top: 30px;
  margin-bottom: 7%;
}
.jar > li {
  width: 100%;
  /* 内容背景 */
  background: #911818;
  box-shadow: 0 0 10px #666;
  text-align: center;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
  padding: 15px;
  margin-right: -24%;
  box-sizing: border-box;
}

.jar > li .pic {
  width: 100%;
  height: 235px;
  background: #fff;
}
#t {
  color: black; 
  margin-left: 45%;
}
.price {
  color: red;
  text-align: left;
  margin: 10px 0;
  font-size: 18px;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
}
.describe1,
.describe2 {
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
  background: #11369e;
  padding: 10px;
  box-sizing: border-box;
}
.describe1 a,
.describe2 a {
  color: #fff;
}
@media screen and (max-width: 1600px) { 
  .w {
    max-width: 1400px;
  }
  /* 内容 */
  .home_jineng {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 30%;
    margin-top: 60px;
  }

  .home_jineng > li {
    width: 90%; /* 在小屏幕上更合适的宽度 */
    margin-right: 0; /* 移除右边距 */
    font-size: 24px; /* 适应小屏幕的字体大小 */
  }
  /*  */
  .home_jineng > li .pic {
    width: 100%;
    height: 370px;
    background: #fff;
  }
  .python {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 30%;
    margin-top: 60px;
  }

  .python > li {
    width: 90%; /* 在小屏幕上更合适的宽度 */
    margin-right: 0; /* 移除右边距 */
    font-size: 24px; /* 适应小屏幕的字体大小 */
  }
  /*  */
  .python > li .pic {
    width: 100%;
    height: 370px;
    background: #fff;
  }
  .jar {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 30%;
    margin-top: 60px;
    margin-bottom: 17%;
  }

  .jar > li {
    width: 90%; /* 在小屏幕上更合适的宽度 */
    margin-right: 0; /* 移除右边距 */
    font-size: 24px; /* 适应小屏幕的字体大小 */
  }
  /*  */
  .jar > li .pic {
    width: 100%;
    height: 370px;
    background: #fff;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -1.3rem;
    background-position: center;
  }
  /* 内容 */
  .home_jineng {
    flex-direction: column;
    align-items: center;
    width: 86%;
    height: 30%;
    margin-left: 15%;
    margin-top: 60px;
  }
  .home_jineng > li {
    width: 90%; /* 在小屏幕上更合适的宽度 */
    margin-right: 0; /* 移除右边距 */
    font-size: 24px; /* 适应小屏幕的字体大小 */
  }
  /*  */
  .home_jineng > li .pic {
    width: 100%;
    height: 370px;
    background: #fff;
  }
  .python {
    flex-direction: column;
    align-items: center;
    width: 86%;
    height: 30%;
    margin-left: 15%;
    margin-top: 60px;
  }

  .python > li {
    width: 90%; /* 在小屏幕上更合适的宽度 */
    margin-right: 0; /* 移除右边距 */
    font-size: 24px; /* 适应小屏幕的字体大小 */
  }
  /*  */
  .python > li .pic {
    width: 100%;
    height: 370px;
    background: #fff;
  }
  .jar {
    flex-direction: column;
    align-items: center;
    width: 86%;
    height: 30%;
    margin-left: 15%;
    margin-top: 60px;
    margin-bottom: 17%;
  }

  .jar > li {
    width: 90%; /* 在小屏幕上更合适的宽度 */
    margin-right: 0; /* 移除右边距 */
    font-size: 24px; /* 适应小屏幕的字体大小 */
  }
  /*  */
  .jar > li .pic {
    width: 100%;
    height: 370px;
    background: #fff;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }

}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer { 
    margin-left: 1rem;
  }
  .home_jineng {
    width: 80%;
    margin-left: 20%;
  }
  .python{
    width: 80%;
    margin-left: 20%;
  }
  .jar{
    width: 80%;
    margin-left: 20%;
  }
  /*  */
  .html {
    margin-bottom: 25%;
  }
  /* 标题 */
  #t {
    margin-left: 50%;
  }
}
/* 最下屏 */
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer { 
    margin-left: 1rem;
  }
  .home_jineng {
    margin-bottom: 15%;
  }
  .html {
    margin-bottom: 25%;
  }
  #t {
    margin-left: 26%;
  }
}
</style>