<template>
  <div>
    <div id="manu">
      <navigation />
    </div>
    <div class="registervideoContainer"></div>
    <h1 class="systemd">可视化系统数据分析</h1>
    <div v-if="loading" id="system">加载中...</div>
    <div v-else id="system">
      <!-- <h1>系统运行数据</h1>
      <p>主机名：{{ systemStats.hostname }}</p>
      <p>系统类型：{{ systemStats.type }}</p>
      <p>操作系统平台：{{ systemStats.platform }}</p>
      <p>总内存：{{ formatBytes(systemStats.totalMemory) }}</p>
      <p>可用内存：{{ formatBytes(systemStats.freeMemory) }}</p>
      <p>平均负载：{{ systemStats.loadAverage }}</p>
      <p>CPU使用率：用户态 {{ systemStats.cpuUsage.user.toFixed(2) }}%, 系统态 {{ systemStats.cpuUsage.sys.toFixed(2) }}%, 空闲 {{ systemStats.cpuUsage.idle.toFixed(2) }}%</p> -->
      <!-- 柱状图 -->
      <div ref="barChart" style="width: 600px; height: 400px;"></div>
    </div>
    <!-- 网站运行数据展示 -->
    <div v-if="!loadingWebsiteStats" id="WebsiteStats">
      <!-- <h2>网站运行数据</h2> -->
      <p>运行时间：{{ websiteStats.uptime }}</p>
      <!-- <p>每分钟请求次数：{{ websiteStats.requestsPerMinute }}</p> -->
      <!-- <p>平均响应时间：{{ websiteStats.responseTime }} ms</p> -->
      <!-- <p>活跃用户数：{{ websiteStats.activeUsers }}</p> -->
      <!-- 饼图 -->
      <div ref="websiteStatsChartContainer" style="width: 400px; height: 300px;"></div>
    </div>
    <!-- 系统架构数据 -->
    <div v-if="!loadingArchitectureStats" id="architectureStatsChart">
      <div ref="architectureStatsChart" style="width: 400px; height: 300px;"></div>
    </div>
    <!-- steam热门游戏实时在线 -->
    <div v-if="!loadingGames" id="Games">
    <iframe
      src="http://127.0.0.1:8050/"
      style="width: 100%; height: 600px; border: none;"
    ></iframe>
  </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      loading: true,
      systemStats: {},
      barChart: null,
      websiteStats: {},
      loadingWebsiteStats: true,
      websiteStatsChart: null,
      architectureStats: {},
      loadingArchitectureStats: true,
      architectureStatsChart: null,
    };
  },
  mounted() {
    this.fetchSystemStats();
    setInterval(this.fetchSystemStats, 1000); // 每秒更新一次数据

    // 加载网站运行数据
    this.fetchWebsiteStats();
    setInterval(this.fetchWebsiteStats, 1000); // 每秒更新一次网站运行数据

    // 加载系统架构数据
    this.fetchArchitectureStats();
    setInterval(this.fetchArchitectureStats, 5000); // 每5秒更新一次系统架构数据
  },
  methods: {
    fetchSystemStats() {
      fetch('http://192.168.3.3:2000/api/system-stats')
        .then(response => response.json())
        .then(data => {
          this.systemStats = data;
          this.loading = false;
          this.updateBarChart(data); // 更新柱状图数据
        })
        .catch(error => {
          console.error('获取数据出错：', error);
          this.loading = false;
        });
    },
    // 获取网站运行数据
    fetchWebsiteStats() {
      fetch('http://192.168.3.3:2000/api/website-stats')
        .then(response => response.json())
        .then(data => {
          this.websiteStats = data;
          this.loadingWebsiteStats = false;
          // 处理 uptime 字段，将其转换为以秒为单位的数值
          const uptimeInSeconds = this.parseUptimeToSeconds(data.uptime);
          // 调用渲染网站运行数据的饼图方法，并传递处理后的 uptime 字段
          this.renderWebsiteStatsChart({
            uptime: uptimeInSeconds,
            requestsPerMinute: data.requestsPerMinute,
            responseTime: data.responseTime,
            activeUsers: data.activeUsers
          });
        })
        .catch(error => {
          console.error('获取网站运行数据出错：', error);
          this.loadingWebsiteStats = false;
        });
    },
    // parseUptimeToSeconds 函数的实现，用于处理 uptime 字段
    parseUptimeToSeconds(uptime) {
      const matches = uptime.match(/(\d+)天 (\d+)小时 (\d+)分钟 (\d+)秒/);
      if (matches && matches.length === 5) {
        const days = parseInt(matches[1]);
        const hours = parseInt(matches[2]);
        const minutes = parseInt(matches[3]);
        const seconds = parseInt(matches[4]);
        return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
      }
      return 0; // 如果格式不匹配，返回 0
    },
    // 获取系统架构数据
    fetchArchitectureStats() {
      fetch('http://192.168.3.3:2000/api/architecture-stats')
        .then(response => response.json())
        .then(data => {
          this.architectureStats = data;
          this.loadingArchitectureStats = false;
          this.renderArchitectureStatsChart(data);
        })
        .catch(error => {
          console.error('获取系统架构数据出错：', error);
          this.loadingArchitectureStats = false;
        });
    },
    // 渲染系统架构数据的旭日图
    renderArchitectureStatsChart(data) {
      if (!this.architectureStatsChart) {
        this.architectureStatsChart = echarts.init(this.$refs.architectureStatsChart);
      }
      const option = {
        title: {
          text: '系统架构数据',
          left: '',
          textStyle: {
            color: '#FFFFFF' // 设置标题颜色为白色
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}'
        },
        series: [
          {
            type: 'sunburst',
            data: data.categories,
            radius: [0, '90%'],
            label: {
              rotate: 'radial'
            }
          }
        ]
      };
      this.architectureStatsChart.setOption(option);
    },
    // 渲染网站运行数据的柱状图
renderWebsiteStatsChart(data) {
  if (!this.websiteStatsChart) {
    this.websiteStatsChart = echarts.init(this.$refs.websiteStatsChartContainer);
  }

  // 将数据转换为数组形式，方便后续排序
  const statsData = [
    { name: '运行时间', value: data.uptime },
    { name: '每分钟请求次数', value: data.requestsPerMinute },
    { name: '平均响应时间', value: data.responseTime },
    { name: '活跃用户数', value: data.activeUsers }
  ];

  // 根据 value 属性进行排序
  statsData.sort((a, b) => b.value - a.value);

  const option = {
    title: {
      text: '网站运行数据',
      left: 'center',
      textStyle: {
            color: '#ffffff'
          }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      axisLabel: {
        formatter: '{value}' // 根据实际情况调整单位
      }
    },
    yAxis: {
      type: 'category',
      data: statsData.map(item => item.name),
      axisLabel: {
        interval: 0,
        rotate: 30
      }
    },
    series: [{
      data: statsData.map(item => item.value),
      type: 'bar',
      label: {
        show: true,
        position: 'right',
        formatter: '{c}' // 显示数据值
      }
    }]
  };
    this.websiteStatsChart.setOption(option);
  },
    //
    updateBarChart(data) {
      if (!this.barChart) {
        this.barChart = echarts.init(this.$refs.barChart);
      }
      
      const option = {
        title: {
          text: '系统运行数据',
          left: 'center',
          textStyle: {
            color: '#FFFFFF' // 设置标题颜色为白色
          }
        },
        xAxis: {
          type: 'category',
          data: ['总内存', '可用内存', '使用内存', 'CPU用户态', 'CPU系统态', 'CPU空闲'],
          textStyle: {
            color: '#ffffff'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [
            data.totalMemory / (1024 * 1024 * 1024),  // 转换为GB
            data.freeMemory / (1024 * 1024 * 1024),  // 转换为GB
            data.usedMemory / (1024 * 1024 * 1024),  // 转换为GB
            data.cpuUsage.user,
            data.cpuUsage.sys,
            data.cpuUsage.idle
          ],
          type: 'bar'
        }]
      };

      this.barChart.setOption(option);
    },
    formatBytes(bytes) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }
  }
};
</script>
  
<style>
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-image: url(../assets/2.jpg);
}
#systemStats {
  color: #fff;
}
#system{
  margin-top: 3rem;
}
p {
  color: #fff;
  margin-right: 55rem;
}
#WebsiteStats {
  color: #fff;
  margin-top: -23rem;
  margin-left: calc(40rem - 5px);
}
#architectureStatsChart {
  color: #fff;
  margin-top: -18rem;
  margin-left: calc(80rem - 5px);
}
.systemd{
  background-color: #29a6c55b;
  color: rgba(189, 235, 220, 0.692);
  margin-left: calc(50rem - 0px);
  margin-right: 49rem;
  padding-left: 1.2rem;
  border-radius: 2rem;
  position: relative;
}
#Games {
  background-color: #29a6c55b;
  color: rgba(189, 235, 220, 0.692);
  border-radius: 2rem;
  position: relative;
}
</style>
