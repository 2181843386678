<template>
    <div id="main">
        <div id="manu">
          <navigation />
        </div>
        <div id="contentBox">
          <!-- 背景 -->
          <div class="registervideoContainer" ></div>
          <div class="w">
            <ul class="Sf">
              <li>
                <div class="sf">
                软件中心
                <br>
                  <a href="https://410121.xyz/s/wyy8.8.0" target="_blank">网易云破解v8.8.0</a>&nbsp;&nbsp;
                  <a href="https://410121.xyz/s/WPS2019" target="_blank" rel="noopener noreferrer">WPS Office2019 破解版</a>&nbsp;&nbsp;
                  <a href="https://410121.xyz/s/HEU_KMS" target="_blank" rel="noopener noreferrer">HEU_KMS_30.4.0</a>&nbsp;&nbsp;
                  <a href="https://410121.xyz/s/Snipaste" target="_blank" rel="noopener noreferrer">Snipaste截图|贴图</a>&nbsp;&nbsp;
                  <a href="https://410121.xyz/s/PixPin" target="_blank" rel="noopener noreferrer">PixPin截图|贴图|长图|GIF</a>&nbsp;&nbsp;
                  <a href="https://410121.xyz/s/kugougainian" target="_blank" rel="noopener noreferrer">酷狗概念版</a>&nbsp;&nbsp;
                  <a href="https://410121.xyz/s/kmx" target="_blank" rel="noopener noreferrer">酷漫熊</a>&nbsp;&nbsp;
                </div>
              </li>
            </ul>
            <ul class="Code">
              <li>
                <div class="code">
                软件封装
                <br>
                nativefier --arch "x64" --platform "windows" --name "软件名字" "网址" --icon "图标路径"  //中文位置自行替换！
                </div>
              </li>
            </ul>
            <ul class="order">
              <li>
                <div class="jcc">
                orderID算法
                outTradeNo算法随机Math //91da8f19cf
                outTradeNo: "91da8f19cf" + Math.random().toString(20).search(2,15) + parseInt(Date.now())
                product_code: "FAST_INSTANT_TRADE_PAY"
                ！！在设置订单超时时间！！
                </div>
              </li>
            </ul>
          <ul class="Lazy">
            <li>
              <div class="Lazyd">
                Lazyload图片延迟加载 || Vue图片懒加载
                <br>
                img v-for="(img,index) in img " v-lazy="img" :key="index"
                <br>
                data数据中回调img的图片数据，让图片进行延迟加载
                <br>
                Vue取消地址栏#号
                在router.js文件 export default new Router({
                mode: 'history' // 添加History之后在vue.config.js文件修改publicPath的参数改为/在重新启动项目就好
                br
                })
              </div>
            </li>
          </ul>
            <ul class="Element">
              <li>
                <div class="element">
                  Element组件开发以及使用方法&nbsp;
                  <a style="color: var(--a-color);" href="https://element.eleme.cn/2.0/#/zh-CN" target="_black">网站快速成型工具</a>
                </div>
              </li>
            </ul>
            <ul class="NVM">
              <li>
                <div class="nvm">
                NVM快速切换NodeJS版本&nbsp;
                <br>
                Windows 安装->
                <a style="color: var(--a-color);" href="https://link.juejin.cn/?target=https%3A%2F%2Fgithub.com%2Fcoreybutler%2Fnvm-windows%2Freleases" target="_black">下载最新安装包，直接安装即可</a>
                <br>
                nvm-noinstall.zip：绿色免安装版，但使用时需进行配置。
                <br>
                nvm-setup.zip：安装版，推荐使用
                <br>
                OS X/Linux 安装
                <br>
                <button @click="copyText('OSX')" class="OSX">
                  <div class="OSX-gradient">复制OSX/Linux安装命令</div>
                </button>
                <textarea ref="hiddenContentOSX" style="display: none;">
                  curl -o- https://raw.githubusercontent.com/creationix/nvm/v0.33.0/install.sh | bash
                </textarea>
                <br>
                <button @click="copyText('Linux')" class="Linux">
                  <div class="Linux-gradient">复制Linux安装命令</div>
                </button>
                <textarea ref="hiddenContentLinux" style="display: none;">
                wget -qO- https://raw.githubusercontent.com/creationix/nvm/v0.33.0/install.sh | bash
                </textarea>
                <br>
                <br>
                安装不同版本Node/Npm
                <br>
                记得在安装前卸载安装的NodeJS,避免安装后出错
                <br>
                查看本地安装的所有版本；有可选参数available，显示所有可下载的版本
                <br>
                nvm list [available]
                <br>
                安装，命令中的版本号可自定义，具体参考命令1查询出来的列表
                <br>
                nvm install 14.15.4
                <br>
                使用特定版本&nbsp;  nvm use 14.15.4
                <br>
                卸载 &nbsp; nvm uninstall 14.15.4
                <br>
                你可以通过以下命令来列出远程服务器上所有的可用版本 &nbsp; OS X/Linux &nbsp; nvm ls-remote
                <br>
                Windows &nbsp; nvm ls available
                <br>
                常用命令&nbsp; 切换版本到14.15.4 &nbsp; nvm use 14.15.4
                <br>
                切换到最新版 &nbsp; nvm use node
                <br>
                设置别名为current-version &nbsp; nvm alias current-version 14.15.4
                <br>
                取消别名 &nbsp; nvm unalias current-version
                <br>
                设置 default 这个特殊别名 &nbsp;  nvm alias default node
                </div>
              </li>
            </ul>
          </div>
          <Pfooter/>
        </div>
    </div>
  </template>
  
<script>
import { ref } from 'vue';

export default {
  setup() {
    const hiddenContentOSX = ref(null);
    const hiddenContentLinux = ref(null);

    const copyText = (os) => {
      let element = os === 'OSX' ? hiddenContentOSX.value : hiddenContentLinux.value;
      if (element) {
        navigator.clipboard.writeText(element.value).then(() => {
          alert(`${os} 安装命令已复制到剪贴板！`);
        }).catch(err => {
          alert('复制失败：' + err);
        });
      }
    };
    return { hiddenContentOSX, hiddenContentLinux, copyText };
  }
};
</script>
  
  
<style scoped>
.w {
	width: 1700px;
	margin: 0 auto;
}
img{
  width: 100%;
  height: 100%;
  display: block;
}
#contentBox {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
    /* //保证盒子始终在窗口中心 */
  background-position: center;
    /* //避免窗口周围留白 */
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 0rem;
  z-index: -1;
  background-image: url(../assets/2.jpg);
  /* 增加虚化效果，参数可以根据需要调整 */
  /* filter: blur(6px);     */
}
.Code {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px #4ba7add2;
  width: 90%;
  margin-top: 100px;
  margin-left: 9%;
}
.Code > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.Code > li.you {
  height: 200px;
}
.order {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px #4ba7add2;
  width: 90%;
  margin-top: 100px;
  margin-left: 9%;
}
.order > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.order > li.you {
  height: 200px;
}
.Lazy {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px #4ba7add2;
  width: 90%;
  margin-top: 100px;
  margin-left: 9%;
}
.Lazy > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.Lazy > li.you {
  height: 200px;
}
.Element {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px #4ba7add2;
  width: 90%;
  margin-top: 50px;
  margin-left: 9%;
}
.Element > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.Element > li.you {
  height: 200px;
}
.NVM {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px var(--box-shadow);
  width: 90%;
  margin-top: 50px;
  margin-left: 9%;
  margin-bottom: 8%;
}
.NVM > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.NVM > li.you {
  height: 200px;
}
.Sf {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px var(--box-shadow);
  width: 90%;
  margin-top: 50px;
  margin-left: 9%;
  margin-bottom: 8%;
}
.Sf > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.Sf > li.you{
  height: 200px;
}
.sf a:hover{
  color: var(--theme-color);
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .Tipping {
    margin-bottom: 9%;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -2.1%;
  }
  .order{
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 84%;
    margin-top: 100px;
    margin-left: 14%;
  }
  .Lazy {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 84%;
    margin-top: 100px;
    margin-left: 14%;
  }
  .Element {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 84%;
    margin-top: 100px;
    margin-left: 14%;
  }
  .NVM {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 84%;
    margin-top: 100px;
    margin-left: 14%;
    margin-bottom: 13%;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer{
    margin-left: 0%;
  }
  .order{
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 74%;
    margin-left: 23%;
    margin-top: 100px;
  }
  .Code{
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 74%;
    margin-left: 23%;
    margin-top: 100px;
  }
  .Lazy {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 74%;
    margin-left: 23%;
    margin-top: 100px;
  }
  .Element {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 74%;
    margin-left: 23%;
    margin-top: 100px;
  }
  .NVM {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 74%;
    margin-left: 23%;
    margin-top: 100px;
    margin-bottom: 14%;
  }
  .Sf{
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 74%;
    margin-left: 23%;
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .order{
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 100%;
    margin-top: 100px;
    margin-left: 2%;
  }
  .Lazy {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 100%;
    margin-top: 100px;
    margin-left: 2%;
  }
  .Element {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 100%;
    margin-top: 100px;
    margin-left: 2%;
  }
  .NVM {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 100%;
    margin-top: 100px;
    margin-left: 2%;
    margin-bottom: 31%;
  }
  .Sf{
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 100%;
    margin-left: 2%;
  }
}
</style>