<template>
    <div class="w">
        <div id="footer">
        <p class="footer">商务联系</p>
        <p>QQ：2285478293</p>
        <p>微信：Shino-Loners</p>
        <router-link to="/sponsor" class="Tipping">打赏</router-link>
         | 
        <a href="#">安卓下载</a>
         | 
        <a href="#">PC下载</a>
        <a class="mail-link" href="mailto:2285478293@qq.com?subject=用户反馈" target="_blank"> | 邮件反馈</a>
        </div>
    </div>
</template>

<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
.footer,p{
    text-align: center;
    margin-right: 0;
}
/* 基础样式，适用于所有设备 */
#footer {
    text-align: center;
    background: #ee8d6f8c;
    color: var(--input-right-bottom-color);
    padding: 20px 207px;
    line-height: 20px;
    position: relative;
    width: calc(88% - 0px);
    margin-top: 5%;
    right: 105px;
    z-index: 100;
}
/*  */
.Tipping,
a{
    color: var(--input-right-bottom-color);
}
.Tipping:hover{
    color: rgba(230, 243, 41, 0.541);
}
/* 使用 @media 查询针对屏幕宽度小于或等于600px的设备调整样式 */
@media screen and (max-width: 768px) {
/* 适应手机ui */
/** 代码元素一样修改 
*/
    .footer{
        text-align: center;
        color: #c296c2;
        z-index: 99;
    }
    #footer {
        text-align: center;
        font-size: 12px; /* 例如，降低字体大小 */
        padding: 5px 0; /* 增加垂直方向的内边距 */
        line-height: 18px; /* 增加行高以改善可读性 */
        position: fixed;
        bottom: 0;
        right: 0;
        width: calc(100% - 3px);
        z-index: 99;
    }
}
</style>