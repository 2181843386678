<template>
  <div id="pdf">
    <div id="manu">
      <navigation />
    </div>
    <div class="registervideoContainer"></div>
    <div>
      <h1 id="v2">Linux安装V2rayA</h1>
      <ul class="v2">
        <h2>下载<a href="https://410121.xyz/s/v2ray" target="_blank">v2ray_5.16.1_amd64.deb</a>
        和<a href="https://410121.xyz/s/v2raya" target="_blank">v2raya_2.2.5.8_amd64.deb</a></h2>
        <br>
        <li>wget https://410121.xyz/s/v2ray/download/v2ray_5.16.1_amd64.deb</li>
        <li>wget https://410121.xyz/s/v2raya/download/v2raya_2.2.5.8_amd64.deb</li>
        <h2>安装dpkg -i 文件名</h2>
        <h2>等待安装完成后systemctl start v2raya启动v2rayA</h2>
        <h2>之后在打开linux的GUI界面</h2>
        <h2>默认地址<li>http://127.0.0.1:2017/</li></h2>
        <h2>不需要的话可以关闭systemctl stop v2raya</h2>
        <img src="../assets/v2raya/1.jpg" alt="">
        <h2>默认进入界面后会有管理员设置，设置完成后导入订阅链接</h2>
        <img src="../assets/v2raya/2.jpg" alt="">
        <img src="../assets/v2raya/3.jpg" alt="">
        <img src="../assets/v2raya/4.jpg" alt="">
        <img src="../assets/v2raya/5.jpg" alt="">
        <img src="../assets/v2raya/6.jpg" alt="">
        <img src="../assets/v2raya/7.jpg" alt="">
        <img src="../assets/v2raya/8.jpg" alt="">
      </ul>
    </div>
  </div>
</template>

<script>

</script>

<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
    /* //保证盒子始终在窗口中心 */
  background-position: center;
    /* //避免窗口周围留白 */
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-image: url(../assets/2.jpg);
  /* 增加虚化效果，参数可以根据需要调整 */
  /* filter: blur(6px);     */
}
#v2, .v2{
  text-align: start;
  margin-left: 15%;
}
</style>
