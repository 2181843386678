<template>
    <div id="main">
        <div id="manu">
          <navigation />
        </div>
        <div id="contentBox">
            <div class="container">
                <h2>注册</h2>
                <el-form :model="user" :rules="rules" ref="registerForm">
                <el-form-item type="text" name="username" placeholder="name"><br>
                  <!-- <input type="text" name="username" placeholder="昵称"><br> -->
                  <el-input v-model="user.name"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <!-- <input type="email" name="email" placeholder="邮箱"><br> -->
                  <el-input v-model="user.email"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="verificationCode">
                  <el-input v-model="user.verificationCode"></el-input>
                  <el-button @click="getVerificationCode">获取验证码</el-button>
                </el-form-item>

                  <input type="password" name="password" placeholder="密码"><br>
                  <input type="password" name="confirm_password" placeholder="确认密码"><br>
                  <button type="submit">注册</button>
                </el-form>
              </div>
          <Pfooter/>
        </div>
    </div>
</template>


<style scoped>
.container {
    width: 400px;
    margin: 100px   auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    padding: 20px;
    background: #fff;
    box-sizing: border-box;
  }
  .container h2{
    text-align: center;
    margin-bottom: 20px;
  }
  input[type="text"], input[type="email"], input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    margin: 10px 0;
  }
  
  button {
    width: 100%;
    background-color: blue;
    color: white;
    padding: 10px;
    border: none;
    text-align: center;
    cursor: pointer;
  }
	
</style>>

