<template>
    <div id="main">
        <div id="manu">
          <navigation />
        </div>
        <div id="contentBox">
          <!-- 背景 -->
          <div class="registervideoContainer" ></div>
          <div class="w">
            <ul class="home_jineng">
            <li>
              <div class="pic">
                nginx安装以及使用 || 前置下载环境
                <br>
                apt-get install gcc
                <br>
                apt-get install zlib1g zlib1g-dev
                <br>
                # Ubuntu14.04的仓库中没有发现openssl-dev，由下面openssl和libssl-dev替代
                <br>
                apt-get install openssl openssl-dev
                <br>
                sudo apt-get install openssl
                <br>
                sudo apt-get install libssl-dev
                <br>
                apt-get install libpcre3 libpcre3-dev
                <br>
                吧下载了的压缩包解压，进文件夹内
                <br>
                运行./configure之后make && make install 等待编译和安装完成在进/usr/local/nginx文件内进行使用
              </div>
            </li>
          </ul>
            <ul class="Baota">
              <li>
                <div class="baota">
                  宝塔入门以及使用
                  <a style="color: #fff;" href="https://www.bt.cn/new/download.html">面板下载</a>
                </div>
              </li>
            </ul>
            <ul class="Termux">
              <li>
                <div class="termux">
                  手机 termux 开启ssh 并连接
                第一步 ：下载  termux 
                <br>
                第二步 ：安装  termux 
                <br>
                打开后安装  pkg install openssh
                <br>
                输入   sshd   # 开启ssh 
                <br>
                查看手机端ip 可以使用其他方法
                <br>
                ifconfig # 查询本地局域网ip
                <br>
                手机上termux查看用户名  
                <br>
                whoami 
                <br>
                第三步：输入命令 passwd   # termux设置密码
                <br>
                手机上termux开启的sshd服务用的是8022端口，而不是常用的22端口
                <br>
                第四步：termux设置自动开启ssh
                <br>
                echo "sshd" >> ~/.bashrc 
                <br>
                参考：
                <br>
                <a style="color: #fff;"  href="https://www.cnblogs.com/znh8/p/13649074.html" target="_black">CSDN</a>
                <br>
                <a style="color: #fff;" href="https://www.cnblogs.com/zeng-qh/p/15491459.html#:~:text=%E7%AC%AC%E4%BA%8C%E6%AD%A5%20%EF%BC%9A%E5%AE%89%E8%A3%85%20termux%20%E6%89%93%E5%BC%80%E5%90%8E%E5%AE%89%E8%A3%85%20pkg,install%20openssh%20%E8%BE%93%E5%85%A5%20sshd%20%23%20%E5%BC%80%E5%90%AFssh" target="_black">博客园</a> 
                </div>
              </li>
            </ul>
          </div>
          <Pfooter/>
        </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    setup() {
      const hiddenContentOSX = ref(null);
      const hiddenContentLinux = ref(null);
  
      const copyText = (os) => {
        let element = os === 'OSX' ? hiddenContentOSX.value : hiddenContentLinux.value;
        if (element) {
          navigator.clipboard.writeText(element.value).then(() => {
            alert(`${os} 安装命令已复制到剪贴板！`);
          }).catch(err => {
            alert('复制失败：' + err);
          });
        }
      };
  
      return { hiddenContentOSX, hiddenContentLinux, copyText };
    }
  };
  </script>
  
  
<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
img{
  width: 100%;
  height: 100%;
  display: block;
}
#home {
  width: 100%;
}
#contentBox {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
  .registervideoContainer {
    background-size: cover;
    background-repeat: no-repeat;
      /* //保证盒子始终在窗口中心 */
    background-position: center;
      /* //避免窗口周围留白 */
    width: 100%;
    height: 100%;
    position: fixed;
    margin-left: 0rem;
    z-index: -1;
    background-image: url(../assets/2.jpg);
    /* 增加虚化效果，参数可以根据需要调整 */
    /* filter: blur(6px);     */
  }
  
  .home_jineng {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 90%;
    margin-top: 50px;
    margin-left: 9%;
  }
  .home_jineng > li {
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
  
  .home_jineng > li.you {
    height: 200px;
  }
  .Baota {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 90%;
    margin-top: 50px;
    margin-left: 9%;
  }
  .Baota > li {
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
  .Baota > li.you {
    height: 200px;
  }
  .Termux {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 90%;
    margin-top: 50px;
    margin-left: 9%;
    margin-bottom: 9%;
  }
  .Termux > li {
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
  .Termux > li.you {
    height: 200px;
  }
  .OSX{
    box-shadow: 0 0 10px #4ba7add2;
    background-color: bisque;
  }
  .OSX-gradient
  {
    position: absolute;
    transition: opacity 0.5s ease-in-out;
    opacity: 0.5;
    background-image: linear-gradient(to right, #ffa07a, #20b2aa); /* 渐变色范例 */
  }
  .OSX:hover .OSX-gradient{
    opacity: 1;
  }
  .Linux {
    box-shadow: 0 0 10px #4ba7add2;
    background-color: bisque; /* 默认背景颜色 */
  }
  
  .Linux-gradient {
    position: absolute;
    transition: opacity 0.5s ease-in-out;
    opacity: 0.5;
    background-image: linear-gradient(to right, #ffa07a, #20b2aa); /* 渐变色范例 */
  }
  .Linux:hover .Linux-gradient {
    opacity: 1;
  }
  /*  */
  .Tipping {
    margin-left: 25%;
    margin-bottom: 8%;
  }
.Tipping:hover{
    color: cornflowerblue;
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .Time {
    margin-bottom: 10%;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer { 
    margin-left: -2%;
  }
  .home_jineng {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 80%;
    margin-top: 50px;
    margin-left: 17%;
  }
  .Baota {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 80%;
    margin-top: 50px;
    margin-left: 17%;
  }
  .Termux {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 80%;
    margin-top: 50px;
    margin-left: 17%;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
  .Termux {
    margin-bottom: 14%;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer { 
    margin-left: 0%;
  }
  .home_jineng, .Baota ,.Termux {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 80%;
    margin-top: 50px;
    margin-left: 19%;
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer { 
    margin-left: 0%;
  }
  .home_jineng, .Baota ,.Termux {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 90%;
    margin-top: 50px;
    margin-left: 9%;
    margin-bottom: 30%;
  }
}
</style>