<template>
  <div id="main">
    <div id="manu">
      <navigation />
    </div>
    <!-- 背景 -->
    <div class="registervideoContainer">
      <video class="fullscreenVideo" id="registervideo" playsinline="" autoplay="" muted="" loop="">
        <source src="../assets/Login.mp4">
      </video>
    </div>
        <div class="w">
          <div id="contentBox">
          <div>
          <!-- 登录部分 -->
          <div class="qiehua">
            <div @click="showLogin" :class="{ active: activeTab === 'login' }">原站</div>
          </div>
          <!-- 过渡 -->
          <transition name="slide-fade">
          <div class="denglu" v-show="activeTab === 'login'">
            <div class="container">
              <div class="qiehua1">没有账号？
                <div @click="showRegister" :class="{ active: activeTab === 'register' }">免费创建！</div>
              </div>
              <form @submit.prevent="handleLogin">
                <div class="form-group">
                  <label for="login_username">账号</label>
                  <input type="text" id="login_username" v-model="loginForm.username" placeholder="用户名/邮箱" required />
                </div>
                <div class="form-group">
                  <label for="login_password">密码</label>
                  <input type="password" id="login_password" v-model="loginForm.password" placeholder="请输入密码" required />
                </div>
                <div class="form-group">
                  <label for="loginCaptchaCode">验证码</label>
                  <input 
                    type="text" 
                    id="loginCaptchaCode" 
                    v-model="loginCaptchaCode" 
                    placeholder="验证码" 
                    required 
                  />
                  <canvas id="loginCaptchaCanvas" width="100" height="30" @click="generateLoginCaptcha"></canvas>
                </div>
                <div class="form-group">
                  <button type="submit" id="Login">登录</button>
                </div>
              </form>
            </div>
          </div>
        </transition>
          <!-- 注册部分 -->
        <transition name="slide-fade">
          <div class="zhuce" v-show="activeTab === 'register'">
              <div class="container">
                <div class="qiehua1">已有账号？
                  <div @click="showLogin" :class="{ active: activeTab === 'login' }">现在登录！</div>
                </div>
                <form @submit.prevent="handleRegister">
                  <div class="form-group">
                    <label for="register_username">账号</label>
                    <input type="text" id="register_username" v-model="registerForm.username" placeholder="请输入用户名" required /><br />
                  </div>
                  <div class="form-group">
                    <label for="register_password">密码</label>
                    <input type="password" id="register_password" v-model="registerForm.password" placeholder="请设置登录密码" required /><br />
                  </div>
                  <div class="form-group">
                    <label for="register_confirm_password">确认密码</label>
                    <input type="password" id="register_confirm_password" v-model="registerForm.confirmPassword" placeholder="确认密码" required /><br />
                  </div>
                  <div class="form-group">
                  <label for="registerCaptchaCode">验证码</label>
                  <input 
                    type="text" 
                    id="registerCaptchaCode" 
                    v-model="registerCaptchaCode" 
                    placeholder="验证码"
                    required
                  />
                  <canvas id="registerCaptchaCanvas" width="100" height="30" @click="generateRegisterCaptcha"></canvas>
                  </div>
                  <div class="form-group">
                    <button type="submit" id="register">注册</button>
                  </div>
                </form>
              </div>
            </div>
          </transition>
          </div> 
        </div>
      <Pfooter />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const activeTab = ref('login');
    const loginCaptchaCode = ref('');
    const registerCaptchaCode = ref('');
    const generatedLoginCaptcha = ref('');
    const generatedRegisterCaptcha = ref('');

    const router = useRouter();
    const loginForm = ref({
      username: '',
      password: ''
    });

    const registerForm = ref({
      username: '',
      email: '',
      password: '',
      confirmPassword: ''
    });

    const showLogin = () => {
      activeTab.value = 'login';
      generateLoginCaptcha();
    };

    const showRegister = () => {
      activeTab.value = 'register';
      generateRegisterCaptcha();
    };

    // 通用的验证码生成函数
    const generateCaptcha = (canvasId, generatedCaptcha) => {
      const canvas = document.getElementById(canvasId);
      const ctx = canvas?.getContext('2d');

      if (!canvas || !ctx) {
        console.error(`${canvasId} Canvas or context not found`);
        return;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let captcha = '';
      for (let i = 0; i < 5; i++) {
        captcha += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      generatedCaptcha.value = captcha;

      ctx.font = '24px Arial';
      ctx.fillStyle = '#000';
      ctx.textBaseline = 'middle';
      ctx.fillText(captcha, 10, canvas.height / 2);

      for (let i = 0; i < 10; i++) {
        ctx.strokeStyle = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.8)`;
        ctx.beginPath();
        ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.stroke();
      }
    };

    // 生成登录验证码
    const generateLoginCaptcha = () => {
      generateCaptcha('loginCaptchaCanvas', generatedLoginCaptcha);
    };

    // 生成注册验证码
    const generateRegisterCaptcha = () => {
      generateCaptcha('registerCaptchaCanvas', generatedRegisterCaptcha);
    };

    onMounted(() => {
      generateLoginCaptcha(); // 页面加载时生成登录验证码
      generateRegisterCaptcha(); // 页面加载时生成注册验证码
    });

    // 处理登录
    const handleLogin = async () => {
      if (loginCaptchaCode.value.toLowerCase() !== generatedLoginCaptcha.value.toLowerCase()) {
        ElNotification({
          title: '错误',
          message: '验证码不正确',
          type: 'error',
        });
        return;
      }
      console.log("登录数据：", loginForm.value);
      try {
        const response = await axios.post('http://192.168.3.3:2000/api/login', loginForm.value);
        console.log(response.data);

        // 保存登录状态
        localStorage.setItem('isLoggedIn', 'true');
        router.push('/');
      } catch (error) {
        console.error('登录失败:', error);
      }
    };

    // 处理注册
    const handleRegister = async () => {
      if (registerCaptchaCode.value.toLowerCase() !== generatedRegisterCaptcha.value.toLowerCase()) {
        ElNotification({
          title: '错误',
          message: '验证码不正确',
          type: 'error',
        });
        return;
      }

      if (registerForm.value.password !== registerForm.value.confirmPassword) {
        ElNotification({
          title: '错误',
          message: '两次输入的密码不匹配',
          type: 'error',
        });
        return;
      }

      console.log("注册数据：", registerForm.value);
      try {
        const response = await axios.post('http://192.168.3.3:2000/api/register', {
          username: registerForm.value.username,
          email: registerForm.value.email,
          password: registerForm.value.password
        });
        console.log(response.data);
        ElNotification({
          title: '成功',
          message: '注册成功，请登录',
          type: 'success',
        });
        showLogin();
      } catch (error) {
        console.error('注册失败:', error);
        ElNotification({
          title: '注册失败',
          message: error.response?.data?.message || '未知错误',
          type: 'error',
        });
      }
    };
    return {
      activeTab,
      showLogin,
      showRegister,
      loginForm,
      registerForm,
      handleLogin,
      handleRegister,
      loginCaptchaCode,
      registerCaptchaCode,
      generateLoginCaptcha,
      generateRegisterCaptcha
    };
  },
}
</script>


<style scoped>
.w{
  width: 1700px;
	margin: 0 auto;
}
/* loginmp4背景 */
#registervideo{
  transform: translateX(0%);
}
/* 视频login */
.registervideoContainer{
  margin-top: -10vh;
  position: fixed;
  z-index: -1;
}
.registervideoContainer:before{
  position: absolute;
}
.active{
  font-size: 24px;
  font-weight: bold;
  color: var(--login-color);
}
.qiehua{
  text-align: center;
  display: flex;
  margin: 0 auto;
  width: 100px;
  line-height: 40px;
}
.qiehua>div{
  flex: 1;
  margin-top: 100px;
  cursor: pointer;
  font-size: 25px;
  color: var(--login-color);
}
.qiehua1{
  display: flex;
  line-height: 40px;
  margin-left: 60px;
  color: var(--shadow-right-bottom-color);
}
.qiehua1>div{
  flex: 1;
  cursor: pointer;
  color: var(--div-color);
}
/* 过渡效果的CSS */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
  position: absolute;
  width: 100%;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  transform: translateX(30%);
  opacity: 0.1;
}

.slide-fade-leave, .slide-fade-enter-to {
  transform: translateX(0%);
  opacity: 0.3;
}

.denglu .container {
  width: 300px;
  padding: 20px;
  background: var(--denglu-color);
  border-radius: 5px;
  box-shadow: 0 2px 5px var(--denglu-box-color);
  margin: 20px auto;
}
.denglu button {
  width: 100%;
  background-color: var(--login-button);
  color: var(--login-color);
  padding: 10px;
  border: none;
  text-align: center;
  cursor: pointer;
}
.form-group {
  margin-bottom: 20px;
}
.container h2 {
  text-align: center;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
}
.form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--input-color);
  box-sizing: border-box;
}
.form-group .button {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: var(--form-button-color);
  color: var(--login-color);
  border: none;
  border-radius: 3px;
  text-align: center;
}
#Login,
#register,
#login_username,
#login_password,
#register_username,
#register_password,
#register_confirm_password,
#captchaCode,
#loginCaptchaCode,
#registerCaptchaCode{
  border-radius: 15px;
  box-shadow: 0 2px 5px var(--shadow-right-bottom-color);
  background-color: var(--login-fize-color);
  color: var(--login-button);
}
#Login:hover,
#register:hover{
  background-color: var(--login-hover-color);
}

.zhuce .container {
  width: 300px;
  padding: 20px;
  background: var(--zhuce-contaier-color);
  border-radius: 5px;
  box-shadow: 0 2px 5px var(--zhuce-color);
  margin: 20px auto;
}
.zhuce .container h2{
  text-align: center;
  margin-bottom: 20px;
}
.zhuce input, input, input {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--input-color);
  box-sizing: border-box;
  margin: 10px 0;
}

.zhuce button {
  width: 100%;
  background-color: var(--login-button);
  color: var(--login-color);
  padding: 10px;
  border: none;
  text-align: center;
  cursor: pointer;
}
/* 验证码 */
#loginCaptchaCode,
#registerCaptchaCode{
  margin-top: -2px;
  width: 60%;
}
#loginCaptchaCanvas,
#registerCaptchaCanvas{
  height: 25px;
  width: 80px;
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  #contentBox {
    width: calc(100% - 5px);
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  #contentBox {
    width: calc(100% - 5px);
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
  #contentBox {
    width: calc(100% - 5px);
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  #contentBox {
    width: calc(100% - 5px);
  }
}
</style>