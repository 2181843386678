<template>
    <div id="main">
        <div id="manu">
          <navigation />
        </div>
        <div id="contentBox">
          <!-- 背景 -->
          <div class="registervideoContainer"></div>
          <div class="w">
            <ul class="Frp">
              <li>
                <div class="frp">
                  梯子 | 魔法 | VPN下载 | v2ray | 翻墙
                </div>
                <h3>v2ray下载</h3>
                <div>
                    <a href="https://410121.xyz/s/v2rayNG" target="_blank">1. 安卓</a>
                    <br>
                    <a href="https://apps.apple.com/us/app/v2box-v2ray-client/id6446814690" target="_blank">2. 苹果mac | <a href="https://apps.apple.com/us/app/v2box-v2ray-client/id6446814690?platform=iphone" target="_blank">苹果iPhone</a> | <a href="https://apps.apple.com/us/app/v2box-v2ray-client/id6446814690?platform=iphone" target="_blank">苹果ipad</a> | 苹果下载需要美区账号</a>
                    <br>
                    <a href="https://410121.xyz/s/v2rayN" target="_blank">3. PC端</a>
                </div>
                <!-- <h3>ssr下载</h3>
                <div>
                  <a href="https://410121.xyz/s/ssrapk" target="_blank">1. 安卓</a>
                  <br>
                  <a href="https://410121.xyz/s/ssrwin" target="_blank">2. PC端</a>
                  <br>
                  <a href="https://410121.xyz/s/ssrmac" target="_blank">3. mac</a>
                </div> -->
              </li>
          </ul>
          <div class="container">
            <div class="Vmress">
            <p>订阅地址：{{ encodedContent }}</p>
            <button @click="copyToClipboard">试用 | 复制</button>
            <ul class="Tipping">
              <router-link to="/sponsor" class="Tipping">打赏</router-link>
            </ul>
            </div>
        </div>
          <ul id="FRP">
            <li>
              <div id="frp">
                <h2 id="android">1. 安卓
                    <!-- <span class="score"><b class="dev__fe"><span class="box"><i>1</i></span></b><span class="slash">/</span><span class="pipe">|</span><b class="dev__ux"><i>0</i></b></span> -->
                </h2>
                <img src="@/assets/android/1.png" alt="" >
                <img src="@/assets/android/2.png" alt="" >
                <img src="@/assets/android/3.png" alt="" >
                <img src="@/assets/android/4.jpg" alt="" >
                <img src="@/assets/android/5.png" alt="" >
                <br>
                <h2 id="ios">2. 苹果</h2>
                苹果美区注册方法以及梯子下载
                <br>
                <a href="https://www.bilibili.com/video/BV17x421C73Z/?buvid=XY08B6D0F1F9AFCA781D25C751EBEB40EEDE4&from_spmid=search.search-result.0.0&is_story_h5=false&mid=OEXfuzL0zvvQZklf%2F6rv7A%3D%3D&p=1&plat_id=116&share_from=ugc&share_medium=android&share_plat=android&share_session_id=1236f350-110a-4754-b77f-831fd54a3025&share_source=WEIXIN&share_tag=s_i&spmid=united.player-video-detail.0.0&timestamp=1716552266&unique_k=rTirMOF&up_id=3546592424495988" target="_blank">美区账号注册</a>
                <br>
                <a href="https://apps.apple.com/us/app/v2box-v2ray-client/id6446814690" target="_blank">v2box</a>
                <br>
                <a href="https://appleid.apple.com/account">ios注册地址</a>
                <br>
                <h3>使用方式</h3>
                <img src="@/assets/ios/1.jpg" alt="" >
                <img src="@/assets/ios/2.jpg" alt="" >
                <br>
                <h2 id="pc">3. PC端</h2>
                
                <img src="@/assets/pc/1.jpg" alt="" >
                <img src="@/assets/pc/2.jpg" alt="" >
                <img src="@/assets/pc/3.jpg" alt="" >
                <img src="@/assets/pc/4.jpg" alt="" >
                <img src="@/assets/pc/5.jpg" alt="" >
                <img src="@/assets/pc/6.jpg" alt="" >
                <img src="@/assets/pc/7.jpg" alt="" >
              </div>
            </li>
          </ul>
          <!-- 悬浮索引 -->
          <div id="sidebar">
            <li>文章目录</li>
            <a href="#android">1. 安卓</a>
            <a href="#ios">2. 苹果</a>
            <a href="#pc">3. PC端</a>
          </div>
          </div>
          <Pfooter/>
        </div>
    </div>
</template>

<script>
export default {
  // props: {
  //   keywords: {
  //       type: String,
  //       required: true
  //     }
  //   },
  data() {
    return {
      encodedContent: "https://bazmaf.503403.xyz",
      goodsList: ["html", "css", "javascript", "html5", "css3", "vue", "react", "node"],
      filteredResults: []
    };
  },
  created() {
        this.filterResults();
  },
  methods: {
    copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.encodedContent;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert("已复制到剪贴板！");
    },
    filterResults() {
      this.filteredResults = this.goodsList.filter(item => item.includes(this.keywords));
    }, 
  },
  // watch: {
  //   keywords() {
  //     this.filterResults();
  //   }
  // }
};
</script>

<style scoped>
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  #home {
    width: 100%;
  }
  #contentBox {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    /* z-index: -1; */
  }
  .registervideoContainer {
    background-size: cover;
    background-repeat: no-repeat;
    /* 保证盒子始终在窗口中心 */
    background-position: center;
    /* 避免窗口周围留白 */
    width: 100%;
    height: 100%;
    position: fixed;
    margin-left: 0rem;
    z-index: -1;
    background-image: url(../assets/2.jpg);
    /* 增加虚化效果，参数可以根据需要调整 */
    /* filter: blur(6px); */
  }
  .Frp {
    color: var(--a-color);
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px var(--box-media);
    width: 90%;
    margin-top: 50px;
    margin-left: 9%;
  }
  .Frp > li {
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
  .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Vmress {
  overflow: hidden;
  color: var(--a-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px var(--box-media);
  width: 90%;
  margin-left: 8%;
  word-break: break-all; /* 使长单词可以在中间断开换行 */
}

.Vmress > p {
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
  white-space: pre-wrap; /* 保留空白符，并允许换行 */
  width: 85%;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: var(--button-color);
  color: var(--input-right-bottom-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--button-hover-color);
}
  #FRP {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px var(--box-media);
    width: 90%;
    margin-top: 50px;
    margin-left: 9%;
    margin-bottom: 11%;
  }
  #FRP > li {
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
  #frp {
    color: var(--a-color);
  }
  .Tipping:hover{
    color: var(--Tipping-hover-color);
}
  #sidebar {
    position: fixed;
    top: 30%;
    right: 0;
    transform: translateY(-50%);
    background: var(--background-color);
    box-shadow: 0 0 10px var(--box-media);
    padding: 10px;
    border-radius: 8px 0 0 8px;
    z-index: 1000;
  }
  #sidebar a {
    color: var(--a-color);
    display: block;
    margin: 5px 0;
    text-decoration: none;
    font-size: 18px;
  }
  #sidebar a:hover {
    text-decoration: underline;
  }
/*  */
/* 基本样式 */
.score {
  display: inline-block;
  position: relative;
}

.dev__fe, .dev__ux {
  display: inline-block;
  position: relative;
  font-weight: bold;
}

.box {
  display: inline-block;
  overflow: hidden;
  height: 1em; /* 根据需要调整 */
  width: 0.5em; /* 根据需要调整，只显示一半 */
  position: relative;
}

.dev__fe i, .dev__ux i {
  display: inline-block;
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em; /* 使其宽度和字体大小一致 */
}

.dev__fe i {
  color: var(--a-color); /* 前端分数的颜色 */
}

.dev__ux i {
    display: inline-block;
    overflow: hidden;
    top: -2rem;
    color: var(--a-color); /* 用户体验分数的颜色 */
}
.dev__slash {
  display: inline-block;
  padding: 0 5px;
  color: var(--a-color); /* 斜杠和竖线的颜色 */
  position: relative;
}

.slash {
  display: inline-block;
  transition: opacity 0.5s ease;
}

.pipe {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  /* top: 0; */
  /* left: 0; */
}

/* 鼠标悬停效果 */
.score:hover .dev__fe i {
  transform: translateX(-50%);
  opacity: 0.7;
}

.score:hover .dev__ux i {
  transform: translateX(50%);
  opacity: 0.7;
}

.score:hover .slash {
  opacity: 0;
}

.score:hover .pipe {
  opacity: 1;
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .registervideoContainer {
    margin-left: -9.5%;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -2%;
  }
  .Frp {
    color: var(--a-color);
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px var(--box-media);
    width: 82%;
    margin-top: 50px;
    margin-left: 16%;
  }
  .Vmress {
    overflow: hidden;
    color: var(--a-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px var(--box-media);
    width: 81%;
    margin-left: 13%;
    word-break: break-all;
  }
  #FRP{
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px var(--box-media);
    width: 81%;
    margin-top: 50px;
    margin-left: 16%;
    margin-bottom: 11%;
}
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: 0%;
  }
  #FRP {
    margin-bottom: 13%;
  }
  .Frp, #FRP {
    width: 75%;
    margin-left: 23%;
  }
  .Vmress {
    width: 75%;
    margin-left: 21%;
  }
  #frp img {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: -0.5%;
  }
  #FRP {
    margin-bottom: 34%;
  }
  .Frp, #FRP {
    width: 90%;
    margin-left: 4%;
  }
  .Vmress {
    width: 90%;
    margin-left: -3%;
  }
  #frp img {
    width: 100%;
  }
}
</style>
