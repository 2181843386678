<template>
  <div id="main">
    <div id="manu">
      <navigation />
    </div>
    <div id="contentBox">
      <!-- 背景 -->
      <div class="registervideoContainer"></div>
      <div class="w">
        <div class="ui">
        <div id="ui">
          <img src="@/assets/17.jpg" alt="">
        </div>
      <div class="message">
        <!-- 新添加的代码块 -->
      <h1 id="message">留言板</h1>
      <input v-model="newMessage" placeholder="输入你的留言" id="message"/>
      <button @click="postMessage">提交留言</button>
      <ul>
        <div class="avatar">
          <img src="../assets/avatar.jpg" alt="Avatar">
        </div>
        <li v-for="message in messages" :key="message.id" id="message">
          {{ message.text }}
          <ul>
            <div class="avataruser">
              <img src="../assets/avatar.jpg" alt="Avatar">
            </div>
            <li v-for="comment in message.comments" :key="comment.id" class="message">
              {{ comment.text }} - {{ comment.user }}
            </li>
            <li>
              <input v-model="newComment[message.id]" placeholder="输入你的评论"/>
              <button @click="postComment(message.id)">回复</button>
            </li>
          </ul>
        </li>
      </ul>
      </div>
      </div>
      </div>
    </div>
    <Pfooter/>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      newMessage: '',
      messages: [],
      newComment: {} // 用于存储每个留言的新评论
    };
  },
  methods: {
    async postMessage() {
      try {
        const response = await axios.post('http://localhost:5000/messages', {
          text: this.newMessage
        });
        console.log(response.data);
        this.newMessage = '';
        this.fetchMessages(); // 更新留言列表
      } catch (error) {
        console.error('Error posting message:', error);
      }
    },
    async postComment(messageId) {
      const text = this.newComment[messageId];
      if (!text) return;
      try {
        const response = await axios.post(`http://localhost:5000/messages/${messageId}/comments`, {
          text
        });
        console.log(response.data);
        this.newComment[messageId] = ''; // 清空输入框
        this.fetchMessages(); // 评论成功后更新留言列表
      } catch (error) {
        console.error('Error posting comment:', error);
      }
    },
    async fetchMessages() {
      try {
        const response = await axios.get('http://localhost:5000/messages');
        this.messages = response.data.map(message => ({
          ...message,
          comments: JSON.parse(message.comments) // 解析评论数组
        }));
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    }
  },
  mounted() {
    this.fetchMessages(); // 页面加载时获取留言列表
  }
};
</script>


<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
img{
  width: 100%;
  height: 100%;
  display: block;
}
#home {
  width: 100%;
}
#contentBox {
  width: 100%;
  height: 100%;
  position: fixed;
  background-repeat: no-repeat;
  z-index: -1;
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-image: url(../assets/2.jpg);
}
.message {
  margin-left: 32%;
  width: 30%;
  max-height: 500px;
  overflow-y: auto;
}
#message {
  margin-left: 17rem;
}
#ui > img {
  margin-left: 30%;
  width: 50%;
  opacity: 0.84;
}
.avatar {
  margin-bottom: -7%;
  margin-left: 44%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.avataruser {
  margin-bottom: -11%;
  margin-left: 70%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  #message {
    margin-left: 25rem;
  }
  .message{
    margin-left: 3.5rem;
  }
  #ui > img {
    margin-left: 30%;
    width: 50%;
    opacity: 0.84;
  }
  .Tipping{
    margin-left: 50%;
  }
  .Tipping:hover{
    color: cornflowerblue;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  #message {
    margin-left: 10rem;
  }
  #ui > img {
    margin-top: 15%;
    margin-left: 30%;
    width: 50%;
    opacity: 0.84;
  }
  .Tipping{
    margin-left: 50%;
  }
  .Tipping:hover{
    color: cornflowerblue;
  }
}
</style>