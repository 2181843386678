import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
// 静态封装
// import './plugins/element.js'
import store from './store/index.js'
// import './element-variables.scss'
// 默认浏览器滚动栏
import './assets/scroll.css'
import navigation from './components/navigation.vue'; // 全局组件
import Pfooter from './components/footer.vue'; // 全局组件
//引入视频插件
// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')  //按官网引会找不到然后报错，换成下面那个

createApp(App)
.use(store)
.use(router)
.component('navigation', navigation)
.component('Pfooter', Pfooter)
.use(ElementPlus)
.mount('#app')