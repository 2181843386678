<template>
  <div id="main">
    <div id="manu">
      <navigation />
    </div>
    <div id="contentBox">
      <div class="registervideoContainer"></div>
      <div class="w">
        <h1 class="VIP">原站免费VIP视频解析</h1>
        <h2 class="shengming" target="_blank">声明：请勿相信视频中广告 | 若播放异常请刷新，或者更换接口尝试哦 | 解析地址均为互联网收集！！！</h2>
        <div class="video" >
          <ul>
            <li v-for="(item, index) in filteredResults" :key="index">
              {{ item }}
            </li>
          </ul>
          <div 
            v-for="(card, index) in cards" 
            :key="index" 
            class="card"
            :class="{ 'turn-to-back': index === currentIndex }"
            >
            <div class="card-front"  v-if="index === currentIndex || currentIndex === null">
              <img :src="card.imgSrc" alt="card image">
              <div class="desc">
                <div class="text">{{ card.description }}</div><br>
              </div>
              <form @submit.prevent="submitForm(index)" id="videoContainer">
                <div class="apis">
                  <input type="radio" :id="'api1-' + index" class="api" :name="'contact' + index" value="https://jx.xmflv.com/?url=" checked>
                  <label :for="'api1-' + index">线路1</label>

                  <input type="radio" :id="'api2-' + index" class="api" :name="'contact' + index" value="https://jx.playerjy.com/?url=">
                  <label :for="'api2-' + index">线路2</label>

                  <input type="radio" :id="'api3-' + index" class="api" :name="'contact' + index" value="" >
                  <label :for="'api3-' + index">线路3</label>
                  <!-- https://jx.2s0.cn/player/?url= -->
                  <input type="radio" :id="'api4-' + index" class="api" :name="'contact' + index" value="https://im1907.top/?jx=" >
                  <label :for="'api4-' + index">线路4</label>
                </div>
                <button type="submit" class="play">播放</button>
              </form>
            </div>
            <div class="card-back" v-if="index === currentIndex">
              <iframe class="player" 
                      :src="playerSrc" 
                      frameborder="0" 
                      allowfullscreen></iframe>
              <div class="button-container">
                <button class="return" title="返回首页" @click="returnToFront">
                  <svg class="icon" viewBox="0 0 1024 1024" width="20" height="20">
                    <path d="M112.228037 296.934806c0 0 80.84119-125.866662 208.754464-193.404871s285.502429-48.095391 388.856355 17.396205 138.146337 120.750131 138.146337 120.750131l89.027639-51.16531c0 0 18.419512-9.209756 18.419512 12.279674s0 321.318146 0 321.318146 0 28.652574-21.48943 18.419512c-18.078751-8.609075-213.596749-120.784924-275.207969-156.176992-33.830503-15.226796-4.107551-27.595498-4.107551-27.595498l85.910649-49.541323c0 0-49.004087-61.923328-120.724549-94.717222-76.773548-40.359196-148.645458-45.144176-236.690724-11.608386-57.407478 21.86703-124.999922 77.853136-173.680645 160.560836C112.228037 296.934806 112.228037 296.934806 112.228037 296.934806zM910.406872 721.592552c0 0-80.84119 125.866662-208.754464 193.404871s-285.502429 48.095391-388.856355-17.396205-138.146337-120.750131-138.146337-120.750131l-89.027639 51.16531c0 0-18.419512 9.209756-18.419512-12.279674s0-321.318146 0-321.318146 0-28.652574 21.48943-18.419512c18.078751 8.609075 213.596749 120.784924 275.207969 156.176992 33.830503 15.226796 4.107551 27.595498 4.107551 27.595498l-85.910649 49.541323c0 0 49.004087 61.923328 120.724549 94.717222 76.773548 40.359196 148.645458 45.144176 236.690724 11.608386 57.407478-21.86703 124.999922-77.853136 173.680645-160.560836C910.406872 721.592552 910.406872 721.592552 910.406872 721.592552z" fill="#ffffff"></path>
                  </svg>
                </button>
                <button class="timer" title="定时关闭" @click="toggleSetTimer">
                  <svg class="icon" viewBox="0 0 1024 1024" width="20" height="20">
                    <path d="M512 854.016q123.989333 0 210.986667-88.021333t86.997333-212.010667-86.997333-210.986667-210.986667-86.997333-210.986667 86.997333-86.997333 210.986667 86.997333 212.010667 210.986667 88.021333zM811.989333 315.989333q84.010667 105.984 84.010667 237.994667 0 157.994667-112 271.018667t-272 112.981333-272-112.981333-112-271.018667 112-271.018667 272-112.981333q130.005333 0 240 86.016l59.989333-61.994667q32 25.984 59.989333 59.989333zM470.016 598.016l0-256 84.010667 0 0 256-84.010667 0zM640 41.984l0 86.016-256 0 0-86.016 256 0z" fill="#ffffff"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <p class="footer">商务联系</p>
        <p>QQ：2285478293</p>
        <p>微信：Shino-Loners</p>
        <router-link to="/sponsor" class="Tipping">打赏</router-link>
         | 
        <a href="#">安卓下载</a>
         | 
        <a href="#">PC下载</a>
        <a class="mail-link" href="mailto:2285478293@qq.com?subject=用户反馈" target="_blank"> | 邮件反馈</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mediaURL: '', // 用户输入的视频链接
      playerSrc: '', // 播放器的源链接
      showTimerOptions: false,
      timer: null,
      delay: null,
      originalCards: [],
      currentIndex: null, // 记录当前播放的视频卡片索引
      cards: [
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mcv8hkc8zk8lnov.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/1.jpg'),
          description: '完美世界'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc00200xf3rir6.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/2.jpg'),
          description: '斗罗大陆Ⅱ绝世唐门'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc0020027yzd9e.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/3.jpg'),
          description: '斗破苍穹年番'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc002007j7p5hn.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/4.jpg'),
          description: '神印王座'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc0020072zgk61.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/5.jpg'),
          description: '剑来'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc00200p49magn.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/7.jpg'),
          description: '全职高手第3季'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc00200aaogpgh.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/6.jpg'),
          description: '仙逆'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc00200sgiyvab.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/8.jpg'),
          description: '元尊'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc00200trfmi0n.html?j_start=478',
          playerSrc: '',
          imgSrc: require('../assets/TP/9.jpg'),
          description: '完美世界剧场版'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc002002kqssyu.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/10.jpg'),
          description: '庆余年第二季'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/0z94k627g0b08eu/p0046u5fcwo.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/0.jpg'),
          description: '速度与激情10（原声版）'
        },
        {
          defaultVideoLink: 'https://v.youku.com/v_nextstage/id_adaec02f09f9469ab8d8.html?spm=a2hja.14919748_WEBCOMIC_JINGXUAN.drawer3.d_zj1_1&s=adaec02f09f9469ab8d8&scm=20140719.manual.8036.show_adaec02f09f9469ab8d8',
          playerSrc: '',
          imgSrc: require('../assets/TP/11.jpg'),
          description: '沧元图第一季&第二季'
        },
        {
          defaultVideoLink: 'https://v.qq.com/x/cover/mzc002003lw1kp8.html',
          playerSrc: '',
          imgSrc: require('../assets/TP/12.jpg'),
          description: '一念永恒 第1季 第2季 第3季'
        },
      ],
    };
  },
  methods: {
    submitForm(index) {
      // 获取选中的解析接口
      let api = document.querySelector(`.api[name="contact${index}"]:checked`).value;
      const defaultVideoLink = this.cards[index].defaultVideoLink;
      this.playerSrc = api + defaultVideoLink;

      // 更新当前播放视频的卡片，并移除其他卡片
      const currentCard = this.cards.splice(index, 1)[0];
      this.cards.unshift(currentCard);

      // 更新当前索引
      this.currentIndex = 0;

      // 播放器初始化
      this.initCKPlayer(this.playerSrc);
    },
    returnToFront() {
      // 恢复卡片的原始顺序
      this.cards = [...this.originalCards];
      this.currentIndex = null; // 取消播放状态
      this.playerSrc = ''; // 清空播放器
    },
    toggleSetTimer() {
      this.showTimerOptions = !this.showTimerOptions;
      if (this.showTimerOptions) {
        this.timer = setInterval(() => {
          if (this.delay <= 0) {
            this.playerSrc = '';
            this.showTimerOptions = false;
            clearInterval(this.timer);
          } else {
            this.delay -= 1;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    },
    initCKPlayer(videoUrl) {
      const videoObject = {
        container: '#videoContainer',
        variable: 'player',
        autoplay: false,
        video: videoUrl
      };
      new this.initCKPlayer(videoObject);
    },
  },
  mounted() {
    this.originalCards = [...this.cards];

    //动态加载js
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/p2p-ckplayer@latest/ckplayer/ckplayer.min.js';
    document.head.appendChild(script);

    script.onload = () => {
      console.log('CKPlayer 加载成功');
    }
  },
};
</script>


<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
img{
  width: 100%;
  height: 100%;
  display: block;
}
#contentBox {
  width: 100%;
  height: 100%;
}
.video {
  margin-left: 7%;
  margin-top: 4%;
  display: flex; /* 启用 Flexbox 布局 */
  flex-wrap: wrap; /* 允许卡片换行 */
  justify-content: flex-start; /* 卡片靠左对齐 */
  align-items: flex-start; /* 如果需要，可以使卡片在顶部对齐 */
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
  /* //保证盒子始终在窗口中心 */
  background-position: center;
  /* //避免窗口周围留白 */
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-image: url(../assets/2.jpg);
  /* 增加虚化效果，参数可以根据需要调整 */
  /* filter: blur(6px); */
}
body {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 1200px;
  background-color: var(--theme-color);
}
.VIP{
  margin-left: 45%;
}
.shengming{
  margin-left: 20%;
  font-size: 25px;
}
.card {
  position: relative;
  transform-style: preserve-3d;
  width: 24rem;
  height: 22rem;
  border-radius: 30px;
  /* box-shadow: 20px 95px 40px var(--shadow-right-bottom-color),
              -20px 95px 40px var(--shadow-left-top-color); */
  transform: rotateY(0deg);
  transition: 1s cubic-bezier(0.83, 0, 0.17, 1);
}
.card.turn-to-back {
  transform: rotateY(180deg);
  /* box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
              20px -20px 20px var(--shadow-left-top-color); */
  width: 80%;
}
.card-front, .card-back {
  width: 80%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: var(--theme-color);
  overflow: hidden;
}

/* 正面 */
.card-front {
  position: absolute;
  height: 26vh;
}

.card-back {
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.card-front img {
  width: 100%;
  height: 60%;
}

.card-front .desc {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.card-front .desc .text {
  margin-left: 0rem;
  margin-top: 0rem;
  font-size: 17px;
  color: var(--shadow-right-bottom-color);
}

.card-front .desc .link {
  color: #d9d9d9;
  height: 5vh;
  width: 40vh;
  margin-left: 1rem;
  font-size: 15px;
}

.card-front form {
  flex: 1;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.card-front {
  margin-top: -3rem;
  line-height: 30px;
  outline: none;
  border: 0;
  border-radius: 25px;
  font-size: 17px;
  box-sizing: border-box;
  box-shadow: inset 2px 2px 4px var(--input-left-top-color), 
              inset -2px -2px 4px var(--input-right-bottom-color);
}

.card-front:hover {
  background: linear-gradient(145deg, #008fb38e, #b68c8c);
}

.card-front form .apis {
  width: 150%;
  line-height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--shadow-right-bottom-color);
  margin-top: 0%;
}

.card-front form .play,
.card-front form .viplay {
  width: 40%;
  line-height: 30px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  outline: none;
  border: 0;
  border-radius: 25px;
  background: linear-gradient(145deg, #838383, #6e6e6e);
  box-shadow:  2px 2px 8px #686868, 
                -2px -2px 8px #8c8c8c;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
}
.card-front form .play:hover,
.card-front form .viplay:hover {
  background: #7a7a7a;
}

.card-front form .play:active,
.card-front form .viplay:active {
  background: linear-gradient(145deg, #6e6e6e, #838383);
  box-shadow:  2px 2px 4px #686868, 
                -2px -2px 4px #8c8c8c;
}

/* 背面 */
.card .card-back {
  display: flex;
  flex-direction: column;
}

.card .card-back .player {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保视频保持纵横比并覆盖整个区域 */
  flex: 9;
  width: 100%;
}

.card .card-back .button-container {
  flex: 1;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card .card-back .return,
.card .card-back .timer {
  outline: none;
  border: 0;
  padding: 6px 6px 4px 6px;
  border-radius: 999px;
  background: var(--return-button-color);
  box-shadow:  2px 2px 4px var(--shadow-right-bottom-color), 
                -2px -2px 4px var(--shadow-left-top-color);
  cursor: pointer;
}

.card .card-back .button-container .return {
  margin: auto;
  transform: translateX(50%);
}

.card .card-back .return:hover,
.card .card-back .timer:hover {
  background: linear-gradient(145deg, #e6a39e, #ffc2bb);
}

.card .card-back .return:active,
.card .card-back .timer:active {
  box-shadow: inset 2px 2px 4px var(--shadow-right-bottom-color), 
            inset -2px -2px 4px var(--shadow-left-top-color);
}
.card.turn-to-back {
  position: relative;
  width: 203vh;
  height: 190vh;
  margin-left: -37vh;
  margin-top: -6vh;
}
.copyright {
  position: fixed;
  bottom: 6px;
}

.copyright :not(.author-link){
  color: #fff;
}

.copyright p {
  margin: 0;
}

.copyright .cprt {
  display: none;
}

.copyright .author-link {
  display: inline-block;
  font-weight: bolder;
  padding: 0 4px;
  background-color: rgba(255, 255, 255, .6);
  border-radius: 4px;
  transition: .3s ease-in-out;
}

.set-timer {
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .6s;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--theme-color);
}

.set-timer .title {
  color: var(--link-color);
  margin: 10px 0;
  font-size: 22px;
}

.set-timer .select-minutes {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.set-timer .select-minutes button {
  min-width: 130px;
  height: 40px;
  font-size: 18px;
  color: gray;
  background-color: var(--theme-color);
  border: none;
  border-radius: 4px;
  transition: .3s;
}

.set-timer .select-minutes button:hover {
  background-color: var(--shadow-left-top-color);
  color: var(--input-right-bottom-color);
  transform: scale(1.1);
}

.show-set-timer {
  opacity: 1;
  z-index: 100;
}
/*  */
.history{
  margin-top: 5%;
  margin-left: 65%;
  color: var(--input-right-bottom-color);
}
#history {
  width: 95% !important;
  white-space: pre-line !important;
}

#history a {
  width: 95% !important;
}
/* 横向时画面比例 */
/* iPhone 6 & 横屏*/
@media screen and (max-width: 667px) and (orientation: landscape) {
  .card.turn-to-back {
    height: 94vh;
  }

  .card .card-back .player {
    flex: 8.5;
  }

  .card .card-back .button-container {
    flex: 1.5;
  }

  footer {
    display: none;
  }
}

/* iPhone 12 Pro Max & 横屏*/
@media screen and (min-width: 668px) and (max-width: 926px) and (orientation: landscape) {
  .card.turn-to-back {
    width: 62.8%;
    height: 90vh;
  }

  .card .card-back .player {
    flex: 8.5;
  }

  .card .card-back .button-container {
    flex: 1.5;
  }
}

/* iPad及以上 & 横屏*/
/* @media screen and (min-width: 927px) {
  .card.turn-to-back {
    position: relative;
    height: 192vh;
    width: 203vh;
    margin-left: -37vh;
    top: -37vh;
  }

  .copyright .cprt {
    display: inline;
  }

  .copyright .author-link:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .1);
  }

  .copyright .author-link:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .1);
  }
} */
#footer {
    text-align: center;
    background: #ee8d6f8c;
    color: var(--input-right-bottom-color);
    padding: 20px 207px;
    line-height: 20px;
    position: relative;
    width: calc(88% - 0px);
    margin-top: 47%;
    right: 105px;
    z-index: 100;
}

/* dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --link-color: #999999;
    --theme-color: #000000;
    --shadow-right-bottom-color: #0f0f0f;
    --shadow-left-top-color: #151515;
    --input-right-bottom-color: #6d6d6d;
    --input-left-top-color: #939393;
    --return-button-color: #E43F5A;
  }

  .card-front, .card-back {
    background-color: #1a1a1d;
  }

  .card-front img {
    opacity: .8;
  }

  .turn-to-back .card-front img {
    opacity: 1;
  }

  .card-front .desc .text,
  .card-front form .apis,
  .card-front form #media-url {
    color: #d7d7d7;
  }

  .card-front form #media-url {
    width: 27rem;
    font-size: 12px;
    background-color: rgba(10, 79, 99, 0.459);
  }

  .card-front form #media-url:hover {
    background: linear-gradient(145deg, #2a9b8c, #fef7ff);
  }

  .card-front form .play,
  .card-front form .viplay {
    background: #E43F5A;
    box-shadow: none;
  }

  .copyright .author-link {
    color:  rgba(228, 63, 90, .8);
    background-color: rgba(255, 255, 255, .8);
  }
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .registervideoContainer {
    margin-left: -0%;
  }
  .shengming {
    font-size: 23px;
  }
  .video {
    margin-left: 16vh;
    margin-top: 10vh;
  }
  .card {
    position: relative;
    width: calc(25% - -0px);
    height: 68vh;
    margin-left: 9vh;
    margin-top: -3vh;
    margin-bottom: -35vh;
  }
  .card-front .desc .text {
    margin-left: 0rem;
    margin-top: 0rem;
    font-size: 20px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front  {
    width: 19rem;
    /* font-size: 12px; */
    /* background-color: rgba(10, 79, 99, 0.459); */
  }
  .card.turn-to-back {
    height: 70vh;
    width: 45vh;
    margin-left: -33vh;
    top: -55vh;
    transform: rotateY(180deg);
    z-index: 9;
    /* box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color); */
  }
  .card-front form .apis {
    width: 153%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 122vh;
    height: 58vh;
    margin-left: -120vh;
    margin-top: 55vh;
  }
}
/* 桌面客户端 */
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .shengming {
    font-size: 15px;
  }
  .video {
    margin-left: 16vh;
  }
  .card {
    position: relative;
    width: calc(40% - -40px);
    margin-left: 3vh;
    margin-top: 0vh;
    margin-bottom: -35vh;
  }
  .card-front .desc .text {
    margin-left: 0rem;
    margin-top: 0rem;
    font-size: 20px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front {
    width: 19rem;
    /* font-size: 12px; */
    /* background-color: rgba(10, 79, 99, 0.459); */
  }
  .card.turn-to-back {
    height: 70vh;
    width: 45vh;
    margin-left: -17.5vh;
    top: -59vh;
    transform: rotateY(180deg);
    z-index: 9;
    /* box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color); */
  }
  .card-front form .apis {
    width: 153%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 120vh;
    height: 63vh;
    margin-left: -95vh;
    margin-top: 65vh;
  }
} 
/* 平板 */
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
  .video {
    margin-left: 16vh;
    margin-top: 15vh;
  }
  .card {
    position: relative;
    width: calc(40% - -40px);
    height: 39rem;
    margin-left: 3vh;
    margin-top: -4vh;
    margin-bottom: -35vh;
  }
  .card-front .desc .text {
    margin-left: 0rem;
    margin-top: 0rem;
    font-size: 20px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front {
    width: 19rem;
    /* font-size: 12px; */
    /* background-color: rgba(10, 79, 99, 0.459); */
  }
  .card-front, 
  .card-back {
    background-color: #1a1a1d;
  }
  .card.turn-to-back {
    height: 70vh;
    width: 45vh;
    margin-left: -40vh;
    top: -60vh;
    transform: rotateY(180deg);
    z-index: 9;
    /* box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color); */
  }
  .card-front form .apis {
    width: 140%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 100vh;
    height: 63vh;
    margin-left: -95vh;
    margin-top: 55vh;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .video {
    margin-top: 55vh;
    margin-left: 21vh;
  }
  .card {
    position: relative;
    width: calc(48% - 5px);
    margin-left: 1vh;
    margin-top: -45vh;
  }
  .card-front .desc .text {
    margin-left: 0rem;
    margin-top: 0rem;
    font-size: 15px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front {
    width: 17rem;
    /* font-size: 12px; */
    /* background-color: rgba(10, 79, 99, 0.459); */
  }
  .card.turn-to-back {
    height: 87vh;
    width: 60vh;
    margin-left: -20vh;
    top: -99vh;
    transform: rotateY(180deg);
    z-index: 9;
    /* box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color); */
  }
  .card-front form .apis {
    width: 165%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 54vh;
    height: 50vh;
    margin-left: -11vh;
    margin-top: 60vh;
  }
}
@media screen and (max-width: 965px) {
  .w{
    max-width: 100%;
  }
  .video {
    margin-top: -20vh;
    margin-left: 20vh;
  }
  .card {
    position: relative;
    width: calc(46% - 0px);
    margin-left: 1vh;
    margin-top: 26vh;
  }
  .card-front .desc .text {
    margin-left: 0rem;
    margin-top: 0rem;
    font-size: 15px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front {
    width: 17rem;
    /* font-size: 12px; */
    /* background-color: rgba(10, 79, 99, 0.459); */
  }
  .card.turn-to-back {
    height: 87vh;
    width: 60vh;
    margin-left: -26vh;
    top: -79vh;
    transform: rotateY(180deg);
    z-index: 9;
    /* box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color); */
  }
  .card-front form .apis {
    width: 165%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 50vh;
    height: 55vh;
    margin-left: -15vh;
    margin-top: 115vh;
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .VIP {
    margin-left: 23%;
    font-size: 25px;
  }
  .shengming{
    margin-left: 1%;
    font-size: 20px;
  }
  .video {
    margin-left: 30%;
    margin-top: 55%;
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
  .card {
    position: relative;
    width: calc(100% - -60px);
    margin-left: -12vh;
    margin-top: -27vh;
    margin-bottom: 50%;
  }
  .card-front .desc .text {
    margin-left: 0rem;
    margin-top: 0rem;
    font-size: 20px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front form #media-url {
    width: 19rem;
    font-size: 12px;
    background-color: rgba(10, 79, 99, 0.459);
  }
  .card.turn-to-back {
    height: 70vh;
    width: 45vh;
    margin-left: -19vh;
    top: -65vh;
    transform: rotateY(180deg);
    /* box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color); */
  }
  .card-front form .apis {
    width: 153%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 44vh;
    height: 55vh;
    margin-left: -1.8vh;
    margin-top: 50vh;
  }
}
</style>