<template>
  <div id="main">
      <div id="manu">
        <navigation />
      </div>
      <div id="contentBox">
        <!-- 背景 -->
        <div class="registervideoContainer" ></div>
          <div class="w">
              <ul>
                <li v-for="(item, index) in filteredResults" :key="index">
                    {{ item }}
                </li>
              </ul>
            <ul class="Frp">
              <li>
                <div class="frp">
                  frp下载
                </div>
                <div>
                    <td><a href="https://410121.xyz/s/frplinux" target="_black">linux</a></td>
                    <br>
                    <td><a href="https://410121.xyz/s/frpwin" target="_black">Windows</a></td>
                    <br>
                </div>
              </li>
          </ul>
          <ul id="FRP">
            <li>
              <div id="frp"  class="code-container">
              <td>frp配置以及使用</td>
              <br>
              <th>服务器外网端</th>
              <br>
              <pre>
                <div class="header">
                <span class="label">TOML</span>
                <button class="copy-button" @click="copyToClipboard">复制</button>
                </div>
                <code class="language-toml">
                  [common]
                  # 公网ip不变  #换服务器要变
                  server_addr = "0.0.0.0"  #需要自行修改ip
                  # 服务器端监听的端口，默认是7000，可自定义
                  bind_port = 7000
                  # 该端口就是以后访问web服务需要用到的端口
                  vhost_http_port = 8000

                  # 监控界面配置
                  dashboard_addr = "0.0.0.0"
                  dashboard_port = 7501
                  # 监控界面账户配置，默认值admin
                  dashboard_user = "admin"
                  dashboard_pwd = "admin"

                  # 启动命令
                  # ./frps -c ./frps.toml&
                  </code>
                </pre>
                <br>
                <br>
                <br>
                <th>服务器内网端</th>
                <br>
                <pre>
                <div class="header">
                <span class="label">TOML</span>
                <button class="copy-button" @click="copyToClipboard1">复制</button>
                </div>
                <code class="language-toml">
                  ### 前面的不用改###
                  # frpc.toml
                  # 从 v0.50.0版本开始
                  # transport.tls.enable的默认值为 true
                  transport.tls.enable = true
                  #公网ip不变  #换服务器要变
                  serverAddr = "0.0.0.0"
                  # 公网服务端通信端口    
                  serverPort = 7000

                  #公网
                  #proxy
                  [[Proxies]]
                  #节点的名称，节点多的话，需要改，不然怕冲突
                  name = "proxy"
                  #协议tcp或者udp，udp没试用（自行尝试）
                  type = "tcp"
                  # 需要暴露的服务的IP，本地的内网环路ip
                  localIP = "127.0.0.1"
                  # 将本地5010端口的服务暴露在公网的80端口
                  localPort = 5010
                  # 暴露服务的公网入口
                  remotePort = 5011
                </code>
                </pre>
              </div>
            </li>
          </ul>
          </div>
        <Pfooter/>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    keywords: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      goodsList: [],
      filteredResults: [],
      encodedContent: `
        [common]
        # 公网ip不变  #换服务器要变
        server_addr = "0.0.0.0"  #需要自行修改ip
        # 服务器端监听的端口，默认是7000，可自定义
        bind_port = 7000
        # 该端口就是以后访问web服务需要用到的端口
        vhost_http_port = 8000

        # 监控界面配置
        dashboard_addr = "0.0.0.0"
        dashboard_port = 7501
        # 监控界面账户配置，默认值admin
        dashboard_user = "admin"
        dashboard_pwd = "admin"

        # 启动命令
        # ./frps -c ./frps.toml&
      `,
      encodedContent1: `
      ### 前面的不用改###
      # frpc.toml
      # 从 v0.50.0版本开始
      # transport.tls.enable的默认值为 true
      transport.tls.enable = true
      #公网ip不变  #换服务器要变
      serverAddr = "0.0.0.0"
      # 公网服务端通信端口    
      serverPort = 7000

      #公网
      #proxy
      [[Proxies]]
      #节点的名称，节点多的话，需要改，不然怕冲突
      name = "proxy"
      #协议tcp或者udp，udp没试用（自行尝试）
      type = "tcp"
      # 需要暴露的服务的IP，本地的内网环路ip
      localIP = "127.0.0.1"
      # 将本地5010端口的服务暴露在公网的80端口
      localPort = 5010
      # 暴露服务的公网入口
      remotePort = 5011
      `
    }
  },
  created() {
    this.filterResults();
  },
  methods: {
    copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.encodedContent;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert("已复制到剪贴板！");
    },
    copyToClipboard1() {
      const el = document.createElement('textarea');
      el.value = this.encodedContent1;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert("已复制到剪贴板！");
    },
    filterResults() {
      this.filteredResults = this.goodsList.filter(item => item.includes(this.keywords));
    }
  },
  watch: {
    keywords() {
      this.filterResults();
    }
  }
};
</script>

<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
img{
  width: 100%;
  height: 100%;
  display: block;
}
#home {
  width: 100%;
}
#contentBox {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  /* z-index: -1; */
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
    /* //保证盒子始终在窗口中心 */
  background-position: center;
    /* //避免窗口周围留白 */
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 0rem;
  z-index: -1;
  background-image: url(../assets/2.jpg);
  /* 增加虚化效果，参数可以根据需要调整 */
  /* filter: blur(6px);     */
}
.Frp {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px var(--box-media);
  width: 90%;
  margin-top: 50px;
  margin-left: 9%;
}
.Frp > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
#FRP{
  /* background: #ffffff8c; */
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px var(--box-media);
  width: 90%;
  height: 10%;
  margin-top: 50px;
  margin-left: 9%;
  margin-bottom: 8%;
}
#FRP > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.label {
  font-weight: bold;
  margin-left: 73rem;
  font-size: 1.2em;
}
.copy-button {
  background-color: #007bff7a;
  color: var(--a-color);
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}
.copy-button:hover {
  background-color: #0056b3;
}
pre {
  /* background: #ffffff8c; */
  height: 50%;
  padding: 0em;
  border-radius: 5px;
  overflow-x: auto;
}
code {
  font-family: 'Courier New', Courier, monospace;
  color: var(--a-color);
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .registervideoContainer {
    margin-left: -3.5%;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -2%;
  }
  .Frp{
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px var(--box-media);
    width: 80%;
    margin-top: 50px;
    margin-left: 17%;
  }
  #FRP {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px var(--box-media);
    width: 82%;
    height: 10%;
    margin-top: 50px;
    margin-left: 15%;
    margin-bottom: 8%;
  }
  .label {
    font-weight: bold;
    margin-left: 45rem;
    font-size: 1.2em;
  }
  .language-toml{
    color: var(--a-color);
    left: -24%;
    position: relative;
  }
  pre {
    /* background: #ffffff8c; */
    height: 50%;
    padding: 0em;
    border-radius: 5px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
  pre{
    height: 50%;
    padding: 0em;
    border-radius: 5px;
    overflow-x: auto;
  }
  #FRP {
    margin-bottom: 15%;
  }
  .header {
    font-weight: bold;
    margin-left: -9rem;
  }
  .copy-button {
    margin-left: -60rem;
  }
  .language-toml{
    color: var(--a-color);
    left: -30%;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: -0%;
  }
  th{
    margin-top: 2%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Frp {
    margin-top: -11%;
  }
  .Frp, #FRP {
    width: 73%;
    margin-left: 24%;
  }
  #FRP {
    margin-bottom: 20%;
  }
  .header {
    font-weight: bold;
    margin-top: -4%;
    margin-left: -16rem;
    font-size: 1.2em;
  }
  .copy-button {
    margin-left: -60rem;
  }
  .language-toml{
    color: var(--a-color);
    font-size: 15px;
    left: 18%;
    position: absolute;
  }
  pre {
    margin-bottom: 68%;
    border-radius: 5px;
    overflow-x: auto; 
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: -0.5%;
  }
  th{
    margin-top: 2%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Frp, #FRP {
    width: 90%;
    margin-left: 5%;
  }
  #FRP {
    margin-bottom: 70%;
  }
  .header {
    font-weight: bold;
    margin-top: -6%;
    margin-left: -34rem;
    font-size: 1.2em;
  }
  .copy-button {
    margin-left: -60rem;
  }
  .language-toml{
    color: var(--a-color);
    font-size: 12px;
    left: -23%;
    position: absolute;
  }
  pre {
    margin-bottom: 51%;
    border-radius: 5px;
    overflow-x: auto; 
  }
}
</style>