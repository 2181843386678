<template>
    <div id="main">
        <div id="manu">
          <navigation />
        </div>
        <div id="contentBox">
          <!-- 背景 -->
          <div class="registervideoContainer" ></div>
            <div class="w">
            <ul class="UI">
              <li>
                <div class="ui">
                    <td>赞助我</td>
                    <br>
                    <td>维护一个项目需要花费大量的时间，如果我的项目能够对你有所帮助，不妨考虑可以打赏我一下？</td>
                    <br>
                    <td>打赏金额随意，一分钱也是支持我不断前进的动力</td>
                    <br>
                </div>
              </li>
            </ul>
            <div class="zu">
               <li>
                <div class="zu">
                    <tr>赞助名单</tr>
                </div>
               </li>
            </div>
            <ul id="ZU">
                <li>
                    <div id="zu">
                        <td>非常感谢上述所有捐赠的小伙伴们！我会继续努力的</td>
                        <br>
                        <td>如果捐赠了却不希望您的名字出现在这里，可以联系我进行相应处理</td>
                        <br>
                        <td>上述记录纯手工记录，如果有遗漏或者希望添加链接请联系我</td>
                    </div>
                </li>
            </ul>
            <ul class="zhanzhu">
                <li>
                    <div>
                        <tr>赞助方式</tr>
                        <img src="@/assets/zf/qq.png">
                    </div>
                </li>
            </ul>
            </div>
          <Pfooter/>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    
  };
  </script>
  
  
<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
img{
  width: 100%;
  height: 100%;
  display: block;
}
#home {
  width: 100%;
}
#contentBox {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
    /* //保证盒子始终在窗口中心 */
  background-position: center;
    /* //避免窗口周围留白 */
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 0rem;
  z-index: -1;
  background-image: url(../assets/2.jpg);
  /* 增加虚化效果，参数可以根据需要调整 */
  /* filter: blur(6px);     */
}
.zu {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px #4ba7add2;
  width: 90%;
  margin-top: 90px;
  margin-left: 9%;
}
.zu > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
#ZU {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px #4ba7add2;
  width: 90%;
  margin-top: 90px;
  margin-left: 9%;
}
#ZU > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.zhanzhu{
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px #4ba7add2;
  width: 90%;
  margin-top: 90px;
  margin-left: 9%;
  margin-bottom: 8%;
}
.zhanzhu > li{
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.ui, .zu, #zu {
  color: rgb(0, 225, 255);
}
img{
  width: 60%;
  height: 60%;
}
.UI {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px #4ba7add2;
  width: 90%;
  margin-top: 90px;
  margin-left: 9%;
}
.UI > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .zhanzhu{
    margin-bottom: 8%
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -1.6rem;
  }
  .UI, .zu, #ZU, .zhanzhu {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 85%;
    margin-top: 90px;
    margin-left: 14%;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
  .zhanzhu {
    margin-bottom: 13%;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer { 
    margin-left: -0%;
  }
  .UI, .zu, #zu, #ZU, .zhanzhu {
    margin-left: 15%;
  }
  .ui, .zu, #zu {
    color: rgb(0, 225, 255);
  }
  .zhanzhu{
    margin-bottom: 25%;
  }
  img{
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer { 
    margin-left: -0%;
  }
  .UI, .zu, #zu, #ZU, .zhanzhu {
    margin-left: 15%;
  }
  .ui, .zu, #zu {
    color: rgb(0, 225, 255);
  }
  .zhanzhu{
    margin-bottom: 25%;
  }
  img{
    width: 100%;
    height: 100%;
  }
}
</style>