<template>
    <div id="search_results_page">
        <component :is="currentComponent" :keywords="keywords" />
    </div>
</template>

<script>

export default {

}
</script>