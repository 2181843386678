<template>
    <div id="main">
        <div id="manu">
          <navigation />
        </div>
        <div id="contentBox">
          <!-- 背景 -->
          <div class="registervideoContainer">
          </div>
            <div class="w">
              <ul class="UI">
              <li>
                <div class="ui">
                    <td>动态&进度</td>
                    <br>
                    <i class="fa fa-refresh" id="fa"></i>
                    测试
                    <br>
                    <br>
                    <i class="fa fa-refresh" id="fe"></i>
                    记录  2024/6/7 网站第一次时光机
                </div>
              </li>
            </ul>
            </div>
          <Pfooter/>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    
  };
  </script>
  
  
  <style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
  img{
    width: 100%;
    height: 100%;
    display: block;
  }
  #home {
    width: 100%;
  }
  #contentBox {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    /* z-index: -1; */
  }
  .registervideoContainer {
    background-size: cover;
    background-repeat: no-repeat;
      /* //保证盒子始终在窗口中心 */
    background-position: center;
      /* //避免窗口周围留白 */
    width: 100%;
    height: 100%;
    position: fixed;
    margin-left: 0rem;
    z-index: -1;
    background-image: url(../assets/2.jpg);
    /* 增加虚化效果，参数可以根据需要调整 */
    /* filter: blur(6px);     */
  }
  .ui > i {
    margin-left: -2rem;
    position: relative; /* 确保伪元素定位相对于父元素 */
}

.ui > i::after {
    content: ''; /* 使用空内容 */
    position: absolute;
    left: 50%; /* 竖杠位置调整 */
    top: -0.3rem;
    height: 2rem; /* 竖杠的高度，根据需要调整 */
    width: 2px; /* 竖杠的宽度 */
    background-color: #000; /* 竖杠颜色 */
    transform: translateX(-50%); /* 竖杠居中 */
}
/*  */
.UI {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px #4ba7add2;
  width: 90%;
  margin-top: 90px;
  margin-left: 9%;
}
.UI > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.UI > li.you {
  height: 200px;
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -1.6rem;
  }
  .UI {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #4ba7add2;
    width: 75%;
    margin-top: 90px;
    margin-left: 17%;
  }
  .UI > li {
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
  .UI > li.you {
    height: 200px;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: 0%;
  }
}
</style>