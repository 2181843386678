<template>
  <div id="main">
      <div id="manu">
        <navigation />
      </div>
      <div id="contentBox">
        <!-- 背景 -->
        <div class="registervideoContainer"></div>
          <div class="w">
              <ul>
                  <li v-for="(item, index) in filteredResults" :key="index">
                      {{ item }}
                  </li>
              </ul>
            <ul class="Mysql">
              <li>
                <div class="mysql">
                  mysql8.0安装&nbsp;<a href="https://blog.csdn.net/qq_40927409/article/details/120040561" target="_black">参考网站</a>
                </div>
              </li>
            </ul>
            <ul class="Time">
              <li>
                <div class="time">
                  MySQL经典8小时nodejs报错 | 解决方法
                  <br>
                  <pre>
const mysql = require('mysql');
const nodemailer = require('nodemailer');
const { db } = require('./config');

//创建连接池
let pool;

function connectToDatabase() {
    console.log('尝试连接数据库...');
    pool = mysql.createPool({
        connectionLimit: 10, // 连接池的连接数限制
        host: db.host,
        port: db.port,
        user: db.user,
        password: db.password,
        database: db.database
    });

    // 从连接池获取连接
    pool.getConnection(function(err, connection) {
        if (err) {
            console.error('获取数据库连接失败:', err);
            // 发送邮件通知
            sendErrorEmail(`获取数据库连接失败: ${err}`);
            // 如果连接失败，延迟一段时间后重试
            setTimeout(connectToDatabase, 2000); // 2秒后重试
            return;
        }

        console.log('数据库连接成功！');
        // 释放连接
        connection.release();

        // 开始定时发送 keep-alive 查询
        startKeepAlive();
    });

    // 在连接池发生错误时重新连接
    pool.on('error', function (err) {
        console.error('数据库连接池错误:', err);
        // 发送邮件通知
        sendErrorEmail(`数据库连接池错误: ${err}`);
        if (err.code === 'PROTOCOL_CONNECTION_LOST') {
            console.log('尝试重新连接数据库...');
            connectToDatabase();
        } else {
            throw err;
        }
    });
}

function startKeepAlive() {
    // 每小时发送一个简单查询，保持连接活跃
    setInterval(() => {
        console.log('发送 keep-alive 查询...');
        pool.getConnection(function(err, connection) {
            if (err) {
                console.error('获取数据库连接失败:', err);
                // 发送邮件通知
                sendErrorEmail(`获取数据库连接失败: ${err}`);
                return;
            }
            
            // 发送查询
            connection.query('SELECT 1', (error) => {
                // 释放连接
                connection.release();
                if (error) {
                    console.error('keep-alive 查询失败:', error);
                    // 发送邮件通知
                    sendErrorEmail(`keep-alive 查询失败: ${error}`);
                    // 如果查询失败，重新连接
                    connectToDatabase();
                } else {
                    console.log('keep-alive 查询成功');
                }
            });
        });
    }, 3600000); // 1小时定时查询一次
}

connectToDatabase();

module.exports = pool;
                  </pre>
                </div>
              </li>
            </ul>
          </div>
        <Pfooter/>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    keywords: {
      type: String,
      required: true
    }    
  },
  watch: {
    keywords() {
      this.filterResults();
    }
  }
}
</script>


<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
img{
  width: 100%;
  height: 100%;
  display: block;
}
#home {
  width: 100%;
}
#contentBox {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  /* z-index: -1; */
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
    /* //保证盒子始终在窗口中心 */
  background-position: center;
    /* //避免窗口周围留白 */
  width: 100%;
  height: 100%;
  position: fixed;
  margin-left: 0rem;
  z-index: -1;
  background-image: url(../assets/2.jpg);
  /* 增加虚化效果，参数可以根据需要调整 */
  /* filter: blur(6px);     */
}
.Mysql {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px var(--box-shadow-nav);
  width: 90%;
  margin-top: 50px;
  margin-left: 9%;
}
.Mysql > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
.mysql, a, .time {
  color: var(--a-color);
}
.Time {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 10px var(--box-shadow-nav);
  width: 90%;
  margin-top: 50px;
  margin-left: 9%;
  margin-bottom: 9%;
}
.Time > li {
  width: 85%;
  font-size: 20px;
  transition: all 0.5s;
  padding: 10px;
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .Time {
    margin-bottom: 10%;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -2%;
  }
  .Mysql {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px var(--box-media);
    width: 75%;
    margin-top: 50px;
    margin-left: 17%;
  }
  .Time {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px var(--box-media);
    width: 80%;
    margin-top: 50px;
    margin-left: 17%;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
  .Time {
    margin-bottom: 20%;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: 0%;
  }
  .Mysql, .Time {
    width: 74%;
    margin-left: 23%;
  }
  pre {
    white-space: pre-wrap;
    border-radius: 5px;
    margin-bottom: 10%;
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: 0%;
  }
  .Mysql, .Time {
    width: 90%;
    margin-left: 5%;
  }
  pre {
    white-space: pre-wrap;
    border-radius: 5px;
    margin-bottom: 10%;
  }
}
</style>