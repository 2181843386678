<template>
    <div id="main">
      <div id="manu">
        <navigation />
      </div>
      <div id="contentBox">
        <!-- 背景 -->
        <div class="registervideoContainer"></div>
        <div class="w">
          <ul class="html">
          <li>
          <div class="jcc">
            <h1 id="t">截图预览</h1>
              <img 
                  :class="['thumbnail', { 'zoomed': isZoomed }]" 
                  src="../assets/3.jpg"
                  alt="Sample Image" 
                  @click="toggleZoom"
                  >
              <img 
                  :class="['thumbnail', { 'zoomed': isZoomed }]" 
                  src="../assets/visualization.jpg"
                  alt="Sample Image" 
                  @click="toggleZoom"
                  >
                  <img 
                  :class="['thumbnail', { 'zoomed': isZoomed }]" 
                  src="../assets/visualization1.jpg"
                  alt="Sample Image" 
                  @click="toggleZoom"
                  >
                  <img 
                  :class="['thumbnail', { 'zoomed': isZoomed }]" 
                  src="../assets/visualization2.jpg"
                  alt="Sample Image" 
                  @click="toggleZoom"
                  >   
                  <img 
                  :class="['thumbnail', { 'zoomed': isZoomed }]" 
                  src="../assets/visualization3.jpg"
                  alt="Sample Image" 
                  @click="toggleZoom"
                  >
                  <img 
                  :class="['thumbnail', { 'zoomed': isZoomed }]" 
                  src="../assets/visualization4.jpg"
                  alt="Sample Image" 
                  @click="toggleZoom"
                  >
              </div>
              <div class="price">￥价格私聊</div>
              <div class="describe1">商务联系</div>
              <div class="describe2">各式程序定做</div>
              </li>
          </ul>
        </div>
        <Pfooter />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isZoomed: false,
      };
    },
    methods: {
      toggleZoom() {
        this.isZoomed = !this.isZoomed;
        if (this.isZoomed) {
          document.body.style.overflow = 'hidden'; // 隐藏页面滚动条
        } else {
          document.body.style.overflow = ''; // 恢复页面滚动条
        }
      }
    }
  };
  </script>
  
  
  <style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
  .pic {
    overflow: auto;
    color: #333;
    font-size: 12px;
  }
  .close:hover,
  .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  #home {
    width: 100%;
  }
  #contentBox {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }
  .registervideoContainer {
      background-size: cover;
      background-repeat: no-repeat;
        /* //保证盒子始终在窗口中心 */
      background-position: center;
        /* //避免窗口周围留白 */
      width: 100%;
      height: 100%;
      position: fixed;
      margin-left: 0rem;
      z-index: -1;
      background-image: url(../assets/2.jpg);
      /* 增加虚化效果，参数可以根据需要调整 */
      /* filter: blur(6px);     */
  }
  .html {
      display: flex;
      justify-content: space-around;
      box-shadow: 0 0 10px #4ba7add2;
      width: 90%;
      margin-top: 11px;
      margin-left: 9%;
      margin-bottom: 8%;
  }
  .html > li {
      width: 85%;
      font-size: 20px;
      transition: all 0.5s;
      padding: 10px;
  }
  .html > li.you {
      height: 200px;
  }
#t {
  color: black; 
  margin-left: 45%;
}
  .price {
    color: red;
    text-align: left;
    margin: 10px 0;
    font-size: 18px;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
  }
  .describe1,
  .describe2 {
    text-align: left;
    font-size: 14px;
    margin-bottom: 10px;
    background: #11369e;
    padding: 10px;
    box-sizing: border-box;
  }
  .describe1 a,
  .describe2 a {
    color: #fff;
  }
  .image-container {
      overflow: auto;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8); /* 背景颜色可以根据需要调整 */
      display: none; /* 初始时隐藏全屏显示 */
      justify-content: center;
      align-items: center;
      z-index: 9999; /* 确保在最顶层 */
      cursor: pointer; /* 光标样式 */
  }
  
  .image-container.show {
    display: flex; /* 显示全屏 */
  }
  
  .thumbnail {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .thumbnail.zoomed {
      margin-top: 11rem;
      margin-left: 3rem;
      transform: scale(1.5);
      max-width: 83%;
      max-height: 100%;
  }
  
  @media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .registervideoContainer {
    margin-left: -8.5rem;
  }
  .home_jineng {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 30%;
    margin-top: 60px;
    margin-bottom: 20%;
  }
  .home_jineng > li {
    width: 90%; /* 在小屏幕上更合适的宽度 */
    margin-right: 0; /* 移除右边距 */
    font-size: 24px; /* 适应小屏幕的字体大小 */
  }
  /*  */
  .home_jineng > li .pic {
    width: 100%;
    height: 370px;
    background: #fff;
  }
  .price {
    font-size: 16px;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -1.6rem;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: -0.1rem;
  }
  .html{
    margin-bottom: 11%;
  }
  .jcc{
    box-shadow: 0 0 10px #4ba7add2;
    width: 90%;
    margin-top: 11px;
    margin-left: 9%;
  }
  #t {
    margin-left: 26%;
  }
  .price,
  .describe1,
  .describe2 {
    width: 90%;
    margin-left: 9%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: -0.1rem;
  }
  
  #t {
    margin-left: 26%;
  }
  .price {
    font-size: 16px;
  }
}
</style>