<template>
    <div id="main">
        <div id="manu">
          <navigation />
        </div>
        <div id="contentBox">
          <!-- 背景 -->
          <div class="registervideoContainer" ></div>
            <div class="w">
              <ul class="Express">
              <li>
                <div class="express">
                  express&nbsp;<a style="color: #fff;" href="https://www.expressjs.com.cn/" target="_black">参考网站</a>
                </div>
                <div>
                    <td>创建mkdir myapp</td>
                    <br>
                    <td>cd myapp</td>
                    <br>
                    <td>npm init</td>
                    <br>
                    <td>entry point: (index.js)</td>
                    <br>
                    <td>npm install express --save</td>
                </div>
              </li>
            </ul>
            </div>
          <Pfooter/>
        </div>
    </div>
  </template>
  
<script>

</script>
  
  
<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
  img{
    width: 100%;
    height: 100%;
    display: block;
  }
  #home {
    width: 100%;
  }
  #contentBox {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    /* z-index: -1; */
  }
  .registervideoContainer {
    background-size: cover;
    background-repeat: no-repeat;
      /* //保证盒子始终在窗口中心 */
    background-position: center;
      /* //避免窗口周围留白 */
    width: 100%;
    height: 100%;
    position: fixed;
    margin-left: 0rem;
    z-index: -1;
    background-image: url(../assets/2.jpg);
    /* 增加虚化效果，参数可以根据需要调整 */
    /* filter: blur(6px);     */
  }
  
  .Express {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #ffffffd2;
    width: 90%;
    margin-top: 50px;
    margin-left: 9%;
  }
  .Express > li {
    color: aliceblue;
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
  @media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer { 
    margin-left: -2%;
  }
  .Express {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #ffffffd2;
    width: 80%;
    margin-top: 50px;
    margin-left: 17%;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 0%;
    width: 100%;
    height: 100%;
  }
}
</style>