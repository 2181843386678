<template>
  <router-view></router-view>
</template>

<script>
// export default {
// created() {
//   (function () {
//     setTimeout(async () => {
//       // 检查 .el-main 是否存在
//       let main = document.querySelector('.el-main')
//       if (main) {
//         console.log(
//           main.clientWidth,
//           main.clientHeight,
//           main.getBoundingClientRect().left,
//           main.getBoundingClientRect().top
//         )
//         // 处理 canvas 元素
//         let ele = document.querySelectorAll('body canvas')
//         Array.from(ele).forEach((item) => {
//           if (item.id !== 'canvas_sakura') {
//             item.style.position = 'absolute'
//             item.style.top = 0
//             item.style.left = 0
//             item.style.zIndex = 1
//             item.classList.add('model')
//           }
//         })
//       } else {
//         console.error(".el-main element not found!")
//       }
//     }, 2800)

//     // 移除爱心跳动效果
//     setTimeout(() => {
//       let eles = document.querySelectorAll('body .model')
//       Array.from(eles).forEach((item) => {
//         if (item.className === 'model') {
//           item.style.display = 'none'
//         }
//       })
//     }, 7800)
//   })();

//   // 核心价值显示效果
//   (function () {
//     var a_idx = 0
//     window.onclick = function (event) {
//       var values = ["富强", "民主", "文明", "和谐", "自由", "平等", "公正", "法治", "爱国", "敬业", "诚信", "友善"]

//       // 创建b元素
//       var heart = document.createElement("b")
//       heart.onselectstart = () => false // 防止拖动
//       document.body.appendChild(heart).innerHTML = values[a_idx]
//       a_idx = (a_idx + 1) % values.length

//       // 设置样式
//       heart.style.cssText = "position: fixed; left: -100%;"

//       var f = 16, // 字体大小
//         x = event.clientX - f / 2, // 横坐标
//         y = event.clientY - f, // 纵坐标
//         c = randomColor(), // 随机颜色
//         a = 1, // 透明度
//         s = 1.2 // 放大缩小

//       var timer = setInterval(function () {
//         if (a <= 0) {
//           document.body.removeChild(heart)
//           clearInterval(timer)
//         } else {
//           heart.style.cssText = `font-size: ${f}px; cursor: default; position: fixed; color: ${c}; left: ${x}px; top: ${y}px; opacity: ${a}; transform: scale(${s});`

//           y--
//           a -= 0.016
//           s += 0.002
//         }
//       }, 15)
//     }

//     // 随机颜色
//     function randomColor() {
//       return `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`
//     }
//   })()
// }
// }
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
body {
  background: #f5f5f5;
  overflow: auto;
}
:root {
  --a-color: #000000;
  --li-color: #0a0505;
  --zhuce-color: #0a0505;;
  --link-color: #ffb5af;
  --theme-color: #22bdecb9;
  --shadow-right-bottom-color: #77a3ccab;
  --shadow-left-top-color: #77a3ccab;
  --input-left-top-color: #d9d9d9;
  --input-right-bottom-color: #ffffff;
  --return-button-color: #ffb5af;
  --box-shadow: #4ba7add2;
  --box-shadow-OSX: #ecf0ef00;
  --box-shadow-nav: #0282faa2;
  --box-media: #4ba7add2;
  --background-color: #2fd7dd;
  --div-color: #3347fc;
  --login-color: #fff;
  --login-fize-color: #0ddde400;
  --login-button: #020a0eaf;;
  --login-hover-color: #6b00f798;
  --denglu-color: #f1efefda;
  --zhuce-contaier-color: #f1efefcc;
  --denglu-box-color: #0000001a;
  --form-button-color: #010e02;
  --input-color: #ccc;
  --button-color: #4ba7ad;
  --button-hover-color: #357179;
  --Tipping-hover-color: #6495ed;
  /* 主页 */
  --home-li-color: #6dd2e43a;
  --home-box-li-color: #666666;
  --home-pic-des-color: #333;
  /* 标签云 */
  --home-tag-color: #00000080;
  --home-tag-item-color: #ddd;
  --home-tag-background-color: #29fdffb5;
}
html {
  font-family: "SF Pro SC","SF Pro Display","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif;
  -webkit-user-select: none;
  user-select: none;
}
li,
a,
p{
  user-select: text;
  list-style: none;
  color: #110505;
  text-decoration: none;
}
#main {
  display: flex;
}
#contentBox {
  width: calc(100% - 200px);
}
</style>
