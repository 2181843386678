<template>
  <div id="main">
    <div id="manu">
      <navigation />
    </div>
    <div id="contentBox">
      <div class="registervideoContainer">
      </div>
      <div class="video" ref="iframeContainer">
      </div>
      <Pfooter/>
    </div>
  </div>
</template>


<script>
export default {
  mounted() {
    const iframe = document.createElement('iframe');
    iframe.src = "https://dan-teng.top/app/tool/play.html";
    iframe.style.width = "100%";
    iframe.style.height = "60rem";
    iframe.style.border = "none";
    iframe.frameBorder = "0";
    iframe.allowFullscreen = true;
    iframe.setAttribute("allow", "autoplay; fullscreen");
    this.$refs.iframeContainer.appendChild(iframe);
  }
};
</script>

<style scoped>
  img{
    width: 100%;
    height: 100%;
    display: block;
  }
  #home {
    width: 100%;
  }
  #contentBox {
    /* background-size: cover; */
    width: 100%;
    height: 100%;
    position: fixed;
    background-repeat: no-repeat;
    z-index: -1;
  }
  .registervideoContainer {
    background-size: cover;
    background-repeat: no-repeat;
      /* //保证盒子始终在窗口中心 */
    background-position: center;
      /* //避免窗口周围留白 */
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    background-image: url(../assets/2.jpg);
    /* 增加虚化效果，参数可以根据需要调整 */
    filter: blur(6px);  
  }
  
  .video {
    /* display: flex; */
    margin-top: -9%;
    margin-left: -1rem;
    height: 100rem;
    justify-content: space-around;
    background-color: #1a1b20;
  }
  @media screen and (max-width: 768px) {
  /* 适应手机ui */
  /** 代码元素一样修改 
    */
    .home_jineng {
      display: flex;
      width: 100%;
      margin-top: 90px;
      align-content: flex-start;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .home_jineng > li {
      width: 80%;
      margin-left: 50px;
      margin-top: 15px;
      background: #911818;
      box-shadow: 0 0 10px #666;
      text-align: '';
      color: #fff;
      cursor: pointer;
      transition: all 0.5s;
      box-sizing: border-box;
    }
  
    .home_jineng > li.you {
      height: 100px;
    }
    .home_jineng > li .pic{
      width: 100%;
      height: 128px;
      background: #ffffff;
    }
  }
  </style>
  
  