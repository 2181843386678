<template>
    <div id="main">
        <div id="manu">
          <navigation />
        </div>
        <div id="contentBox">
          <!-- 背景 -->
          <div class="registervideoContainer" ></div>
            <div class="w">
              <ul class="UI">
              <li>
                <div class="ui">
                  <img src="@/assets/16.jpg">
                  <div id="about">
                    <h1>自述</h1>
                    <h2>所在地：广州</h2>
                    <h2>目前：自由职业</h2>
                    <div id="mali">
                      <h2><a href="mailto:2285478293@qq.com">联系方式：2285478293@qq.com</a></h2>
                    </div>
                    <h2>相关经历</h2>
                    <h2>相关作品</h2>
                  </div>
                    <router-link to="/sponsor" class="Tipping">打赏我</router-link>  
                </div>
              </li>
            </ul>
            </div>
          <Pfooter/>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    
  };
  </script>
  
  
  <style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
  #home {
    width: 100%;
  }
  #contentBox {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }
  .registervideoContainer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: fixed;
    margin-left: 0rem;
    z-index: -1;
    background-image: url(../assets/2.jpg);
  }
  .UI {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #073894d2;
    width: 90%;
    margin-top: 90px;
    margin-left: 9%;
    margin-bottom: 8%;
  }
  .ui > img {
    width: 100%;
    opacity: 0.84; 
  }
  .UI > li {
    width: 85%;
    font-size: 20px;
    transition: all 0.5s;
    padding: 10px;
  }
  .UI > li.you {
    height: 200px;
  }
  #about{
    font-size: 15px;
  }
  #about, #mali > h2 > a{
    color: #000000;
  }
  .Tipping{
    margin-left: 40%;
  }
  .Tipping:hover{
    color: cornflowerblue;
  }
  #mali{
    margin-left: 40%;
    margin-top: -2%;
  }
 /* 大型设备（桌面，1024px以上） */
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  #mali{
    margin-left: 40%;
    margin-top: -4.5%;
  }
}
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .registervideoContainer {
    margin-left: -1.6rem;
  }
}
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .registervideoContainer {
    margin-left: 0%;
  }
  .UI {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 10px #073894d2;
    width: 90%;
    margin-top: 90px;
    margin-left: 9%;
    margin-bottom: 8%;
  }
  .ui > img {
    width: 100%;
    opacity: 0.84; 
  }
  #about{
    font-size: 15px;
  }
  #about, #mali > h2 > a{
    color: #000000;
  }
  .Tipping{
    margin-left: 40%;
  }
  .Tipping:hover{
    color: cornflowerblue;
  }
  #mali{
    margin-left: 1%;
    margin-top: -2%;
  }
}
</style>