<template>
    <div class="game-detail">
      <h1>{{ game.title }}</h1>
      <img :src="game.image" alt="Game Image" />
      <div class="game-info">
        <p><strong>开发者:</strong> {{ game.user }}</p>
        <p><strong>查看次数:</strong> {{ game.views }}</p>
        <p><strong>游戏描述:</strong> {{ game.description }}</p>
      </div>
      <button @click="goBack">返回</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        game: {},
        loading: true,
      };
    },
    methods: {
      async fetchGameData() {
        const gameId = this.$route.params.gameId; // 从路由中获取游戏 ID
        try {
          const response = await axios.get(`https://api.example.com/games/${gameId}`); // 替换为真实 API
          this.game = response.data;
        } catch (error) {
          console.error('获取游戏信息失败', error);
        } finally {
          this.loading = false;
        }
      },
      goBack() {
        this.$router.go(-1); // 返回上一页
      }
    },
    mounted() {
      this.fetchGameData();
    },
  };
  </script>
  
  <style scoped>
  .game-detail {
    text-align: center;
  }
  
  .game-info {
    margin-top: 20px;
  }
  
  img {
    width: 100%;
    max-width: 400px; /* 控制最大宽度 */
    height: auto;
  }
  </style>
  