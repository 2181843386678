// apk封装路由模式
// import { createRouter, createWebHashHistory } from 'vue-router'
// web前端历史路由模式
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import SearchResults from '../views/SearchResults.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchResults,
    meta: { displayName: 'SearchResults', title: '搜索检索一系列有可能的事物', }
  },
  {
    path: '/frp',
    name: 'frp',
    component: () => import('../views/frp.vue'),
    meta: { displayName: 'frp', title: '内网穿透以及公网的使用方式', }
  },
  {
    path: '/v2ray',
    name: 'v2ray',
    component: () => import('../views/v2ray.vue'),
    meta: { displayName: 'v2ray', title: '如何选择合适的VPN服务,VPN使用指南：安全上网的步骤,设置VPN的详细教程,VPN连接问题解决方案,如何在不同设备上配置VPN', }
  },
  {
    path: '/acc',
    name: 'acc',
    component: () => import('../views/acc.vue'),
    meta: { displayName: 'acc', title: '一款可以自建的游戏加速器工具', }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: { displayName: '登录', title: '登录以及注册', }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue'),
    meta: { displayName: '注册', title: '登录以及注册', }
  },
  {
    path: '/code',
    name: 'code',
    component: () => import('../views/code.vue'),
    meta: { displayName: '商品', title: '首页！代码类的东西以及开源项目', }
  },
  {
    path: '/html',
    name: 'html',
    component: () => import('../views/html.vue'),
    meta: { displayName: 'html', title: '只关于html的文章代码', }
  },
  {
    path: '/vis',
    name: 'vis',
    component: () => import('../views/vis.vue'),
    meta: { displayName: '可视化', title: '用python以及vue代码开源的可视化界面和客户端程序！', }
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/video.vue'),
    meta: { displayName: '视频', title: '视频解析的基本步骤与技巧', }
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('../views/vip.vue'),
    meta: { displayName: '视频1', title: '免费的vip视频在线解析的基本步骤与技巧', }
  },
  {
    path: '/kaobei',
    name: 'kaobei',
    component: () => import('../views/kaobei.vue'),
    meta: { displayName: '拷贝漫画', title: '拷贝漫画-漫画阅读平台-转载', }
  },
  {
    path: '/miaoqu',
    name: 'miaoqu',
    component: () => import('../views/miaoqu.vue'),
    meta: { displayName: '喵趣漫画', title: '喵趣漫画-免费漫画阅读平台-转载', }
  },
  {
    path: '/dp',
    name: 'dp',
    component: () => import('../views/dp.vue'),
    meta: { displayName: '大屏', title: '提升数据分析效率的可视化大屏', }
  },
  // 
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/message.vue'),
    meta: { displayName: '留言&评论', title: '留言&评论', }
  },
  {
    path: '/OM',
    name: 'OM',
    component: () => import('../views/OM.vue'),
    meta: { displayName: '运维服务器', title: '运维服务器监控工具使用教程', }
  },
  {
    path: '/soft',
    name: 'soft',
    component: () => import('../views/soft.vue'),
    meta: { displayName: '前端', title: '提升前端性能的10个技巧,10个技巧提升前端性能与用户体验', }
  },
  {
    path: '/back',
    name: 'back',
    component: () => import('../views/back.vue'),
    meta: { displayName: '后端', title: '优化后端性能的实用技巧', }
  },
  {
    path: '/Nav',
    name: 'Nav',
    component: () => import('../views/Nav.vue'),
    meta: { displayName: '数据库', title: '数据库管理系统的类型及特点', }
  },
  //
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue'),
    meta: { displayName: '关于', title: '关于', }
  },
  {
    path: '/Ti',
    name: 'Ti',
    component: () => import('../views/Ti.vue'),
    meta: { displayName: '时光机', title: '时光机', }
  },
  {
    path: '/spo',
    name: 'spo',
    component: () => import('../views/spo.vue'),
    meta: { displayName: '赞助', title: '赞助活动中的创意元素', }
  },
  {
    path: '/Xmind',
    name: 'Xmind',
    component: () => import('../views/Xmind.vue'),
    meta: { displayName: '思维导图', title: '思维导图：提升思维能力的利器探索思维导图在教育中的潜力', }
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import('../views/pdf.vue'),
    meta: { displayName: 'pdf转换器', title: '在线PDF转换器的使用指南与技巧,在线PDF转换器的最佳使用场景', }
  },
  {
    path: '/V2rayA',
    name: 'V2rayA',
    component: () => import('../views/V2rayA.vue'),
    meta: { displayName: 'V2rayA', title: '如何使用V2rayA进行科学上网,V2rayA配置指南：从入门到精通,V2rayA的安装步骤详解,V2rayA常见问题及解决方案,V2rayA使用技巧与优化建议', }
  },
  {
    path: '/vvip',
    name: 'vvip',
    component: () => import('../views/vvip.vue'),
    meta: { displayName: 'vvip', title: '免费vip视频在线解析', }
  },
  {
    path: '/GameDetail/:gameId',
    name: 'GameDetail',
    component: () => import('../views/GameDetailComponent.vue'),
    meta: { displayName: '游戏详情页', title: '游戏详情页' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 确保在 router 实例化之后使用 router.beforeEach
router.beforeEach((to, from, next) => {
  const defaultTitle = '免费工具站的无限可能性 - 原站';
  const pageTitle = to.meta.title || to.meta.displayName || defaultTitle;
  document.title = pageTitle;
  next();
});

export default router